import { MenuProps } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MUISelect from '@material-ui/core/Select'
import React from 'react'

import { separatorLight } from 'src/_vars'
import MenuItem, { MenuItemPropsWithE2E } from 'src/atoms/MenuItem/MenuItem'
import { MediaQueryCheck } from 'src/css-helpers/MediaQueryCheck'
import { isDesktop } from 'src/lib/browser'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { WithTranslation, withTranslation } from 'src/lib/i18n/i18n'
import { removeProps } from 'src/lib/react'
import {
	eventStopper,
	getClosestElement,
	isSelectOptionsArray,
	Key,
	MapDelegate,
	mapSelectOptions,
	multipleSort,
	randomBetween,
	sortAlphaNum,
} from 'src/lib/utils'
import { MobileDialogWithArrow } from 'src/molecules/MobileDIalog'
import { MobileDialogProps } from 'src/molecules/MobileDIalog/MobileDialog'
import { darkRed, mainBlack, secondaryBlack } from 'src/refactor/colors'
import styled, { padPx } from 'src/styles'
import { DefaultProps, ElementEvent, MUIComponent, MUIComponentClass, SelectOption, SortingFn } from 'src/travelsuit'

import TextField from '../TextField/TextField'
import { MobileDropdownContent } from './MobileDropdownContent'

// TODO: Only one usage for one option. Concider refactoring and removing
interface OptionConfig {
	sort?: keyof SelectOption | SortingFn<SelectOption>
	hideNull: boolean
	nullItemLabel: React.ReactNode
	nullItemStringLabel: string
	interactiveDisabledItem?: boolean
}

interface SearchConfig {
	enabled: boolean
	placeholder: string
	hideOptionsWhenEmpty: boolean
	searchDelegate: string | ((value: any, option: SelectOption) => string)
	doesOptionMatchSearch?: (search: string, option: SelectOption) => boolean
}

interface FilledSelectConfigs {
	searchConfig: SearchConfig
	optionConfig: OptionConfig
}

type ViewVariant = 'white' | 'gray'

export type SelectInputRef = { node: HTMLInputElement }

export function simulateFocus(ref: React.RefObject<SelectInputRef>) {
	if (!isDesktop()) {
		ref.current?.node?.parentElement?.dispatchEvent(new MouseEvent('mousedown', { bubbles: true, cancelable: true }))
		return
	}
	ref.current?.node?.previousElementSibling?.dispatchEvent(
		new MouseEvent('mousedown', { bubbles: true, cancelable: true }),
	)
}

export interface SelectProps {
	selectRef?: React.Ref<SelectInputRef>
	renderOptionLabel?: (option: SelectOption) => React.ReactNode
	MenuItemComponent?: React.ComponentType<MenuItemPropsWithE2E>
	mobileDialogPlaceholder?: OptionConfig['nullItemLabel']
	isMultiSelect?: boolean

	externalValidation?: boolean
	hideLabelOnMissingValue?: boolean
	// behavior
	/** Actual value of the option. Is compared with [option.value] */
	value?: any
	/** Input label */
	label?: React.ReactNode
	/** List of options. To render custom items, use [props.children]. Currently, [children] does NOT support searching. */
	options?: SelectOption[] | any[]
	variant?: ViewVariant
	searchConfig?: Partial<SearchConfig>
	optionConfig?: Partial<OptionConfig>

	fullWidth?: boolean
	noShadow?: boolean

	// direct pass to MUI
	error?: boolean
	helperText?: React.ReactNode
	leading?: React.ReactNode
	trailing?: React.ReactNode
	required?: boolean
	disabled?: boolean
	renderValue?(currentValue: any, currentOption?: SelectOption): React.ReactNode
	disableScrollLock?: boolean
	maxDropdownHeight?: number | string
	renderLabelForMenuItems?: MapDelegate<any, React.ReactNode>

	// shortcut aliases
	searchable?: SearchConfig['enabled']
	hideNull?: OptionConfig['hideNull']
	sort?: OptionConfig['sort']
	/** This will be the label on the "null" value **in the list**.
	 * If you want to change what appears on the control itself, or specify different labels for value in dropdown list and
	 *  on the control, use [placeholder]
	 */
	nullLabel?: OptionConfig['nullItemLabel']
	nullString?: string
	/** This will appear on the control itself when no value is selected, and also on the "null" value in the dropdown
	 * list; unless otherwise specified via [nullLabel]
	 */
	placeholder?: OptionConfig['nullItemLabel']
	placeholderColor?: string

	// callbacks
	onChange?(value: any, e?: React.SyntheticEvent<HTMLElement>): void
	onBlur?(): void
	onClick?(): void

	//e2eForItem
	showE2EForAllMenuItems?: boolean
	e2eForFirstMenuItem?: string
	commonE2EPrefixForMenuItem?: string
	e2eMenu?: string

	name?: string

	wrapWhiteSpaceForSelectMenu?: boolean

	mobileDialogProps?: MobileDialogProps
	renderInModal?: boolean

	menuDecoration?: React.ReactNode

	optionsDisabled?: boolean

	MenuProps?: Partial<MenuProps>

	mobileVariant?: ViewVariant
}

export type IProps = SelectProps & DefaultProps & WithTranslation

interface IState {
	id: string
	search: string
	isMobileSelectOpen: boolean
}

const AdornmentContainer = styled.span`
	display: inline-block;
	& > * {
		vertical-align: middle;
	}
`
const PlaceHolder = styled.span<{ color?: string }>`
	color: ${(props) => props.color ?? secondaryBlack};
`

const LeadingContainer = styled(AdornmentContainer)`
	margin-right: 10px;
`

export const TrailingContainer = styled(AdornmentContainer)`
	margin-left: 10px;
`
const SearchTextField = styled(TextField)``

const StyledInputLabel = styled(removeProps(InputLabel)<{ transparent?: boolean }>('transparent'))`
	opacity: ${(props) => (props.transparent ? 0 : 1)};
`

export const SelectMenuItem = styled(MenuItem)<{ interactiveDisabledItem?: boolean }>`
	&.MuiButtonBase-root.Mui-disabled {
		opacity: 1;
		${({ interactiveDisabledItem }) => interactiveDisabledItem && `pointer-events: auto`}

		&:hover {
			background-color: transparent;
		}
	}

	${(p) => p.theme.breakpoints.down('md')} {
		padding-left: 0;
		padding-right: 0;
		border-top: 1px solid ${separatorLight};
	}
`

type StyledMUISelectStyleProps = Pick<IProps, 'wrapWhiteSpaceForSelectMenu'> & {
	tsVariant: IProps['variant']
} & E2E

type StyledFormControlStyleProps = React.PropsWithChildren<
	Pick<IProps, 'helperText' | 'maxDropdownHeight' | 'noShadow'> & E2E
>

export const PADDING_BY_VARIANT = {
	default: '13.32px 20px',
	white: '10px 20px',
}

const StyledMUISelect = styled(
	removeProps(MUISelect)<StyledMUISelectStyleProps>('e2e', 'tsVariant', 'wrapWhiteSpaceForSelectMenu'),
).attrs(addE2EAttrs)`
	${(p) => p.theme.breakpoints.down('md')} {
		& .MuiSelect-icon {
			display: none;
		}
	}

	&.Mui-error .MuiSelect-select {
		border-color: ${darkRed};
	}

	.MuiSelect-icon {
		right: ${(p) => p.theme.spacing(2)}px;
	}

	& .MuiSelect-root {
		${(props) =>
			props.tsVariant === 'white'
				? `
			background-color: white;
			padding: ${PADDING_BY_VARIANT.white};
		`
				: `
			padding: ${PADDING_BY_VARIANT.default};
		`}
		padding-right: ${(p) => p.theme.spacing(7)}px;

		height: 1.526em;
	}

	&:not(.Mui-error) .MuiSelect-root {
		&:hover,
		&:active,
		&:focus {
			${PlaceHolder} {
				color: ${mainBlack};
			}
		}
	}

	& .MuiSelect-selectMenu {
		white-space: ${(p) => (p.wrapWhiteSpaceForSelectMenu ? 'pre-wrap' : '')};
	}
`

const StyledFormControl = styled(
	removeProps(FormControl)<StyledFormControlStyleProps>('helperText', 'maxDropdownHeight', 'noShadow'),
)`
	${(p) => p.theme.breakpoints.down('md')} {
		position: relative;
		&::after {
			content: '';
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			position: absolute;
		}
	}

	& .MuiPopover-root {
		position: absolute !important;

		${(p) => p.theme.breakpoints.down('md')} {
			display: none;
		}
	}

	& .MuiInputBase-input {
		box-shadow: ${(p) => (p.noShadow ? 'none !important' : '')};
	}

	& .MuiPaper-root {
		top: ${(props) => (props.helperText ? 'calc(100% - 30px)' : '100%')} !important;
		left: 0 !important;
		right: 0 !important;
		max-height: unset !important;
	}

	& .MuiList-root {
		max-height: ${(p) => (p.maxDropdownHeight ? padPx(p.maxDropdownHeight) : '')};
	}
`

const StyledMobileDialog = styled(MobileDialogWithArrow)`
	padding-bottom: 0;
`

class Select extends MUIComponent<IProps, IState> {
	//TODO: Handle e2e
	public static muiName = (MUISelect as MUIComponentClass<any, any>).muiName
	public static defaultProps: Partial<IProps> = {
		disableScrollLock: true,
		searchable: false,
		fullWidth: true,
		renderValue(v, o) {
			return typeof o?.label === 'string' ? o.label : v
		},
		maxDropdownHeight: 300,
		wrapWhiteSpaceForSelectMenu: false,
	}

	public state: IState = {
		id: this.props.id ?? 'slct-' + randomBetween(100000),
		search: '',
		isMobileSelectOpen: false,
	}

	private searchRef = React.createRef<HTMLInputElement>()
	private selectContainerRef = React.createRef<HTMLDivElement>()
	private MAX_RESULTS_FILTERED = 20

	constructor(props: IProps) {
		super(props)

		if ((props.searchable || props.searchConfig?.enabled) && !props.options && props.children) {
			console.error('When using `children`, `searchable` can not be be true.')
		}
	}

	private openMobileSelect = () => {
		this.setState({ isMobileSelectOpen: true })
	}

	private closeMobileSelect = () => {
		this.setState({ isMobileSelectOpen: false })
	}

	public render() {
		const {
			className,
			value,
			onChange,
			onClick,
			required,
			disabled,
			label,
			leading,
			trailing,
			t,
			children,
			variant,
			renderValue,
			disableScrollLock,
			nullLabel,
			helperText,
			maxDropdownHeight,
			e2e,
			fullWidth,
			noShadow,
			showE2EForAllMenuItems,
			e2eForFirstMenuItem,
			commonE2EPrefixForMenuItem,
			e2eMenu,
			name,
			onBlur,
			wrapWhiteSpaceForSelectMenu,
			mobileDialogProps,
			renderOptionLabel,
			mobileDialogPlaceholder,
			isMultiSelect,
			placeholderColor,
			renderInModal,
			menuDecoration,
			optionsDisabled,
			MenuProps,
			mobileVariant,
		} = this.props

		const { options } = this

		const { externalValidation, hideLabelOnMissingValue } = this.props
		const error = this.props.error || (!externalValidation && required && [null, undefined, ''].includes(value))

		let { placeholder = t('select.none', 'None') } = this.props
		placeholder = <PlaceHolder color={error ? darkRed : placeholderColor}>{placeholder}</PlaceHolder>
		const { search, id: selectId } = this.state
		const { searchConfig, optionConfig } = this.getConfigValues()
		const filteredOptions = options ? this.sorted(this.filtered(options)) : []
		const shouldShowNullOption =
			!optionConfig.hideNull &&
			(!searchConfig.hideOptionsWhenEmpty ||
				(search.length &&
					this.filterSearchTerm(search, {
						value: null,
						label: optionConfig.nullItemStringLabel ?? optionConfig.nullItemLabel,
					})))

		const shouldHideLabel = hideLabelOnMissingValue && !value
		const MenuItemComponent = this.props.MenuItemComponent ?? SelectMenuItem

		const dialogPlaceholder = mobileDialogPlaceholder ?? this.props.placeholder

		return (
			<MediaQueryCheck matcher={(theme) => theme.breakpoints.down('md')}>
				{(isMobile) => {
					const menuItems = options.length
						? filteredOptions.map((option, i) => {
								const isOptionDisabled = optionsDisabled || option.value?.disabled || option.disabled

								return (
									<MenuItemComponent
										interactiveDisabledItem={optionConfig?.interactiveDisabledItem}
										{...(showE2EForAllMenuItems
											? addE2EAttrs({
													e2e:
														(commonE2EPrefixForMenuItem ? `${commonE2EPrefixForMenuItem}-` : '') +
														option.label?.toString().replace(/\s+/g, ''),
												})
											: null)}
										{...(e2eForFirstMenuItem && i === 0 ? addE2EAttrs({ e2e: e2eForFirstMenuItem }) : null)}
										key={i}
										tabIndex={0}
										value={option.value}
										disabled={isOptionDisabled}
										selected={value === option.value}
										onClick={
											isMobile
												? (e: React.SyntheticEvent<HTMLElement>) => {
														onChange?.(option.value, e)
														if (!isMultiSelect) {
															this.closeMobileSelect()
														}
													}
												: undefined
										}
									>
										{renderOptionLabel ? renderOptionLabel(option) : option.label}
									</MenuItemComponent>
								)
							})
						: children

					return (
						<StyledFormControl
							className={className}
							fullWidth={fullWidth}
							noShadow={noShadow}
							helperText={helperText}
							maxDropdownHeight={maxDropdownHeight}
							onMouseDown={isMobile && !this.state.isMobileSelectOpen ? this.openMobileSelect : undefined}
						>
							{label ? (
								<StyledInputLabel
									id={selectId}
									shrink
									error={error}
									required={required && !shouldHideLabel}
									transparent={shouldHideLabel}
								>
									{label}
								</StyledInputLabel>
							) : null}

							<StyledMUISelect
								inputRef={this.props.selectRef}
								ref={this.selectContainerRef}
								name={name}
								tsVariant={isMobile ? mobileVariant ?? variant : variant}
								displayEmpty
								multiple={isMultiSelect}
								value={value ?? ''}
								onChange={(e: ElementEvent<HTMLInputElement>) =>
									this.setState({ search: '' }, () => {
										// using "key" causes the component to update too fast for the ripple animation to display properly
										setTimeout(() => onChange?.(e.target?.value, e), 50)
									})
								}
								onBlur={onBlur}
								required={required}
								disabled={disabled}
								labelId={selectId}
								error={error}
								fullWidth
								SelectDisplayProps={{ 'data-test': e2e }}
								renderValue={(val: any) => (
									<>
										{leading ? <LeadingContainer>{leading}</LeadingContainer> : null}
										{![undefined, null, ''].includes(val)
											? renderValue!(val, options.find((o) => o.value === val)!)
											: placeholder ?? optionConfig.nullItemLabel}
										{trailing ? <TrailingContainer>{trailing}</TrailingContainer> : null}
									</>
								)}
								onOpen={() => {
									onClick?.()
									this.onOpenDropdown()
								}}
								MenuProps={{
									...MenuProps,
									container: this.selectContainerRef.current,
									getContentAnchorEl: null,
									anchorOrigin: {
										vertical: 'bottom',
										horizontal: 'left',
									},
									disableScrollLock,
									MenuListProps: {
										'data-test': e2eMenu,
									},
									disablePortal: renderInModal,
								}}
								wrapWhiteSpaceForSelectMenu={wrapWhiteSpaceForSelectMenu}
							>
								{searchConfig.enabled ? (
									<SearchTextField
										tabIndex={0}
										autoFocus
										onClickCapture={eventStopper()}
										onKeyUpCapture={this.stopSearchPropagation}
										onKeyDownCapture={this.stopSearchPropagation}
										value={search}
										inputRef={this.searchRef}
										onChange={(e) => this.setState({ search: e.target.value })}
										placeholder={searchConfig.placeholder}
									/>
								) : null}
								{!required && !optionConfig.hideNull ? (
									<MenuItem
										value={undefined}
										tabIndex={0}
										style={{
											display: !shouldShowNullOption ? 'none' : undefined,
										}}
									>
										{nullLabel ?? optionConfig.nullItemLabel}
									</MenuItem>
								) : null}
								{menuDecoration}
								{menuItems}
							</StyledMUISelect>
							{this.state.isMobileSelectOpen && (
								<StyledMobileDialog {...mobileDialogProps} open onClose={() => this.closeMobileSelect()}>
									<MobileDropdownContent title={dialogPlaceholder} fullHeight={mobileDialogProps?.fullHeight}>
										{menuItems}
									</MobileDropdownContent>
								</StyledMobileDialog>
							)}
							{helperText ? <FormHelperText error={error}>{helperText}</FormHelperText> : null}
						</StyledFormControl>
					)
				}}
			</MediaQueryCheck>
		)
	}

	private stopSearchPropagation = eventStopper.stopPropagation(
		{
			condition(e: React.KeyboardEvent<HTMLElement>) {
				return ![Key.Down, Key.Up].includes(e.key as Key)
			},
		},
		(e) => {
			if (e.type !== 'keyup') {
				return
			}
			if ([Key.Down].includes(e.key as Key)) {
				const listElement = getClosestElement<HTMLDivElement>(this.searchRef.current, { selector: '.MuiList-root' })

				if (listElement) {
					const firstListItem = listElement.querySelector<HTMLElement>(
						'li:not([style*="display: none"]):not([style*="display:none"])',
					)!
					if (firstListItem) {
						firstListItem.focus()
					}
					e.preventDefault()
					e.stopPropagation()
				}
			}
		},
	)

	private filtered([...options]: SelectOption[]): SelectOption[] {
		const { search } = this.state
		const { searchConfig } = this.getConfigValues()

		if (searchConfig.hideOptionsWhenEmpty && !search.length) {
			return []
		}

		if (!search.length) {
			return options
		}

		return options.filter((opt) => this.filterSearchTerm(search, opt)).slice(0, this.MAX_RESULTS_FILTERED)
	}

	/** returns true if option is shown under the current [state.search] */
	private filterSearchTerm(search: string, opt: SelectOption): boolean {
		search = search.trim().toLocaleLowerCase()
		if (!search) {
			return true
		}

		return (
			this.props.searchConfig?.doesOptionMatchSearch?.(search, opt) ??
			new RegExp(`(\\b|[+])${search}`).test(this.searchableValueForOption(opt).toLocaleLowerCase())
		)
	}

	private sorted([...options]: SelectOption[]): SelectOption[] {
		const { search } = this.state
		const { optionConfig } = this.getConfigValues()

		if (!optionConfig.sort) {
			return options
		}

		return options.sort(
			multipleSort([
				(a, b) => {
					const terms = search.trim().toLocaleLowerCase()
					const compA = this.searchableValueForOption(a).toLocaleLowerCase().startsWith(terms)
					const compB = this.searchableValueForOption(b).toLocaleLowerCase().startsWith(terms)

					if (compA && compB) {
						return 0
					}

					return compA ? -1 : compB ? 1 : 0
				},
				sortAlphaNum((a: any) => this.searchableValueForOption(a).toLocaleLowerCase()),
			]),
		)
	}

	private searchableValueForOption(option: SelectOption): string {
		const { searchConfig } = this.getConfigValues()

		if (typeof searchConfig.searchDelegate === 'string') {
			return option[searchConfig.searchDelegate]
		}

		return searchConfig.searchDelegate(option.value, option)
	}

	private onOpenDropdown() {
		this.setState({ search: '' }, () => {
			setTimeout(() => {
				this.searchRef.current?.focus()
			}, 75)
		})
	}

	private get options(): SelectOption[] {
		const { options = [], renderLabelForMenuItems } = this.props

		if (isSelectOptionsArray(options)) {
			return options
		}

		return mapSelectOptions(options, { mapLabel: renderLabelForMenuItems })
	}

	private getConfigValues(): FilledSelectConfigs {
		const { searchable, hideNull, sort, placeholder, nullLabel, nullString, t } = this.props
		return {
			searchConfig: {
				enabled: searchable!,
				hideOptionsWhenEmpty: false,
				placeholder: t('select.placeholder', 'Search'),
				searchDelegate(val) {
					return String(val)
				},
				...this.props.searchConfig,
			},
			optionConfig: {
				hideNull: hideNull!,
				nullItemLabel: nullLabel ?? placeholder ?? t('select.none', 'None'),
				nullItemStringLabel: nullString ?? t('select.none', 'None'),
				sort,
				...this.props.optionConfig,
			},
		}
	}
}

export default withTranslation()(Select)
