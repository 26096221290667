import { loadData, WithAbortRequestConfig } from 'src/lib/requests'

import { LocationTypes } from './index'

export interface Airport {
	name: string
	code: string
	city_code: string
	longitude: number
	country_iso: string
	latitude: number
	id: number
	type: LocationTypes.Airport
}

export interface Office {
	address: string
	name: string
	id: string
	type: LocationTypes.Office
	latitude?: number
	longitude?: number
}

export interface Station {
	id: string
	number: number
	name_local: string
	name_en: string | null
	type: LocationTypes.Rail
	latitude?: number
	longitude?: number
}

export interface RailStation {
	country_code: string | null
	country_name: string | null
	id: string
	name: string
	type: LocationTypes.RailStation | LocationTypes.RailStationGroup
}

export interface City {
	id: number
	city: string
	state: string | null
	country: string
	airports: Airport[]
	stations: Station[]
	offices: Office[]
	type: LocationTypes.City
	iata_code?: string
	state_code?: string | null
	latitude: number
	longitude: number
}

export async function searchSites({
	search,
	getAbortRequestConfig,
}: { search: string } & WithAbortRequestConfig): Promise<City[]> {
	search = search.toLocaleLowerCase().trim()
	if (!search) {
		return []
	}

	try {
		const { data } = await loadData<City[]>({ resourcePath: 'sites', params: { search }, getAbortRequestConfig })
		return data
	} catch (e) {
		console.error(e)
		return []
	}
}

export function filterEmptyCities(cities: City[]): City[] {
	return cities.filter((city) => city.airports.length || city.offices.length || city.stations.length)
}
