import React from 'react'

import Button from 'src/atoms/Button/Button'
import HandsEyesImage from 'src/refactor/assets/empty_states/empty_state_hands_eyes.svg'
import InDivImage from 'src/refactor/assets/empty_states/empty_state_in_div.svg'
import InDivNarrowImage from 'src/refactor/assets/empty_states/empty_state_in_div_narrow.svg'
import PaperPlaneImage from 'src/refactor/assets/empty_states/empty_state_paperplane.svg'
import HandsPlaneImage from 'src/refactor/assets/empty_states/empty_state_plane_hands.svg'
import { brightTurquoise } from 'src/refactor/colors'
import { font, FontType } from 'src/refactor/fonts'
import styled, { css, flex } from 'src/styles'
import { DefaultElProps } from 'src/travelsuit'

type EmptyStateVariants = 'paperplane' | 'handsPlane' | 'handsEyes' | 'inDiv' | 'inDivNarrow'

interface IProps extends Omit<DefaultElProps<'div'>, 'title'> {
	variant: EmptyStateVariants
	title: React.ReactNode
	subtitle?: React.ReactNode
	actionLabel?: string
	actionHandler?(e: React.MouseEvent<any>): void
}

interface IState {
	height?: number
}

const variantToImage: Record<EmptyStateVariants, string> = {
	paperplane: PaperPlaneImage,
	handsPlane: HandsPlaneImage,
	handsEyes: HandsEyesImage,
	inDiv: InDivImage,
	inDivNarrow: InDivNarrowImage,
}

const EmptyStateContainer = styled.div`
	${flex({ direction: 'column', justify: 'space-between' })}
	width: 100%;
	height: 100%;
`

export const emptyStateTitleStyles = css`
	${font(FontType.Comfortaa, { size: 32, weight: 'bold' })}
	margin-bottom: 10px;
	color: ${brightTurquoise};
`

const Title = styled.h2`
	${emptyStateTitleStyles}

	${(p) => p.theme.breakpoints.down('md')} {
		${font(FontType.Comfortaa, { size: 20, weight: 'bold' })}
		padding: 0 ${(p) => p.theme.spacing(3)}px;
	}
`

const Subtitle = styled.h3`
	${font({ size: 18, weight: 600 })}
	margin-bottom: 40px;

	${(p) => p.theme.breakpoints.down('md')} {
		${font(FontType.Comfortaa, { size: 14, weight: 'bold' })}
		padding: 0 ${(p) => p.theme.spacing(3)}px;
		margin-bottom: ${(p) => p.theme.spacing(2)}px;
	}
`

export const EmptyStateContentContainer = styled.div<{ variant: EmptyStateVariants }>`
	${flex({ direction: 'column' })}
	${(props) => (['inDiv', 'inDivNarrow'].includes(props.variant) ? 'padding: 0px 20px;' : '')}
	
	text-align: center;

	margin-top: ${(p) => p.theme.spacing(2)}px;

	${(p) => p.theme.breakpoints.up('lg')} {
		margin-top: ${(p) => p.theme.spacing(16)}px;
	}
`

export const EmptyStateImgContainer = styled.div<{ variant: EmptyStateVariants }>`
	${(props) =>
		!['inDiv', 'inDivNarrow'].includes(props.variant)
			? `
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;`
			: ''}
`

export const EmptyStateButton = styled(Button)<{ variant: EmptyStateVariants }>`
	width: 200px;
	margin: 0 auto;
	${(props) => (['inDiv', 'inDivNarrow'].includes(props.variant) ? `margin-bottom: 20px;` : '')}
`

export const EmptyStateImg = styled.img<Pick<IProps, 'variant'>>`
	position: relative;
	width: 100%;
	height: 100%;
`

const Spacer = styled.div<{ height?: number }>`
	${(props) => (props.height ? `height: ${props.height}px;` : '')}
`

// TASK migrate to React.FunctionComponent OR remove this if not possible
class EmptyState extends React.Component<IProps, IState> {
	public state: IState = { height: undefined }

	private origBodyPosition: string
	private containerRef = React.createRef<HTMLDivElement>()

	public componentDidMount() {
		this.origBodyPosition = document.body.style.position
		document.body.style.position = 'relative'
	}

	public componentWilUnmount() {
		document.body.style.position = this.origBodyPosition
	}

	public render() {
		const { className, title, subtitle, actionLabel, actionHandler, variant, children } = this.props

		const inDiv = ['inDiv', 'inDivNarrow'].includes(variant)

		const content = (
			<>
				<EmptyStateContentContainer variant={variant} data-test="EmptyState" className={className}>
					<Title data-test="EmptyState.Title">{title}</Title>
					<Subtitle data-test="EmptyState.Subtitle">{subtitle}</Subtitle>
					{children}
					{actionHandler && actionLabel ? (
						<EmptyStateButton
							variant="inDiv"
							color="primary"
							onClick={(e: React.MouseEvent<any>) => actionHandler(e)}
							data-test="EmptyState.ActionHandler"
						>
							{actionLabel}
						</EmptyStateButton>
					) : null}
				</EmptyStateContentContainer>
				{!inDiv ? <Spacer height={this.state.height} /> : null}
				<EmptyStateImgContainer variant={variant} ref={this.containerRef}>
					<EmptyStateImg onLoad={() => this.handleLoadImage()} src={variantToImage[variant]} variant={variant} />
				</EmptyStateImgContainer>
			</>
		)

		return inDiv ? <EmptyStateContainer>{content}</EmptyStateContainer> : <>{content}</>
	}

	private handleLoadImage() {
		if (!this.containerRef.current) {
			return
		}
		const rect = this.containerRef.current.getBoundingClientRect()
		const height = rect.bottom - rect.top
		if (height) {
			this.setState({ height })
		}
	}
}

export default EmptyState
