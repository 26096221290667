import { useCallback, useState } from 'react'

export function useAsyncAction(initialState = false) {
	const [isRequestInProgress, setIsRequestInProgress] = useState(initialState)

	const performAsyncAction = useCallback(async function performAsyncAction<T>(action: () => Promise<T> | T) {
		setIsRequestInProgress(true)
		try {
			return await action()
		} finally {
			setIsRequestInProgress(false)
		}
	}, [])

	return {
		isRequestInProgress,
		setIsRequestInProgress,
		performAsyncAction,
	}
}
