import { padPx } from './units'

export interface PositionProps<T = number | string> {
	top: T
	right: T
	bottom: T
	left: T
}

export interface SizeProps<T = number | string> {
	width: T
	height: T
}

interface SymmetricProps {
	vertical: number | string
	horizontal: number | string
}

export type SideProps = PositionProps
export type AllSizeValues = [number, number, number, number] | number[]
export type SymmetricSizeValues = [number, number] | number[]
export type SizeArgs = Partial<PositionProps> | AllSizeValues | number
export type SymmetricArgs = Partial<SymmetricProps> | SymmetricSizeValues

interface CSSSizable {
	all(value: number | number[]): string
	only(options: SizeArgs): string
	symmetric(options: SymmetricArgs): string
	edge(options: Partial<SideProps>): string
	guess(options: any): string
}

export function isSizeProps(arg: any): arg is Partial<PositionProps> {
	if (!arg) {
		return false
	}
	return ['top', 'left', 'right', 'bottom'].some((side) => arg[side] !== undefined)
}

export function isSymmetricProps(arg: any): arg is Partial<SymmetricProps> {
	if (!arg) {
		return false
	}
	return ['horizontal', 'vertical'].some((side) => arg[side] !== undefined)
}

export function isSizeArray(arg: any): arg is AllSizeValues {
	if (!arg) {
		return false
	}
	return arg instanceof Array && arg.length === 4 && arg.every((v) => v !== undefined)
}

export function isSymmetricArray(arg: any): arg is SymmetricSizeValues {
	if (!arg) {
		return false
	}
	return arg instanceof Array && arg.length === 2 && arg.every((v) => v !== undefined)
}

export function isSymmetricArg(arg: any): arg is Partial<SymmetricProps> | SymmetricSizeValues {
	return isSymmetricArray(arg) || isSymmetricProps(arg)
}

export function isSizeArg(arg: any): arg is Partial<PositionProps> | AllSizeValues {
	return isSizeArray(arg) || isSizeProps(arg)
}

export function genericSizable(property: string): Pick<CSSSizable, 'all' | 'only' | 'symmetric' | 'guess'> {
	return {
		all(value) {
			if (!(value instanceof Array)) {
				value = [value]
			}
			return `${property}: ${value.map(padPx).join(' ')};`
		},
		only(options) {
			const out: string[] = []
			if (isSizeArray(options) || isSymmetricArray(options)) {
				return `${property}: ${options.map(padPx).join(' ')};`
			} else if (isSizeProps(options)) {
				for (const side of ['top', 'right', 'bottom', 'left']) {
					const v = options[side]
					if (options[side] !== undefined) {
						out.push(`${property}-${side}: ${padPx(v)};`)
					}
				}
			} else if (['number', 'string'].includes(typeof options)) {
				return `${property}: ${padPx(options)};`
			}
			return out.join('\n')
		},
		symmetric(options) {
			const out: string[] = ['0', '0']
			if (isSymmetricArray(options)) {
				return `${property}: ${options.map(padPx).join(' ')};`
			} else {
				if (options.vertical !== undefined) {
					out[0] = padPx(options.vertical)
				}
				if (options.horizontal !== undefined) {
					out[1] = padPx(options.horizontal)
				}
			}
			return `${property}: ${out.join(' ')};`
		},
		guess(options) {
			if (isSizeArg(options)) {
				return this.only(options)
			} else if (isSymmetricArg(options)) {
				return this.symmetric(options)
			}
			return this.all(options)
		},
	}
}

export function genericCornerSizable(property: string): Pick<CSSSizable, 'all' | 'edge'> {
	return {
		all(value) {
			return `${property}: ${padPx(value)};`
		},
		// only(options) {
		// 	const out: string[] = []
		// 	for (const side of ['top-left', 'top-right', 'bottom-right', 'bottom-left']) {
		// 		const v = options[side]
		// 		if (options[side] !== undefined) {
		// 			out.push(`${property}-${side}: ${padPx(v)};`)
		// 		}
		// 	}
		// 	return out.join('\n')
		// },
		edge(options) {
			const out: string[] = ['0', '0', '0', '0']
			if (options.top) {
				out[0] = padPx(options.top)
				out[1] = padPx(options.top)
			}
			if (options.right) {
				out[1] = padPx(options.right)
				out[2] = padPx(options.right)
			}
			if (options.bottom) {
				out[2] = padPx(options.bottom)
				out[3] = padPx(options.bottom)
			}
			if (options.left) {
				out[3] = padPx(options.bottom)
				out[0] = padPx(options.bottom)
			}
			return `${property}: ${out.join(' ')};`
		},
	}
}

/** returns size as object width {width, height} */
export function sizeProps<T = number>(width: T | number | string, height: T | number | string = width) {
	return { width: width as T, height: height as T }
}

export function size(width: number | string, height: number | string = width, important = false) {
	return sizeStyleFromObject(sizeProps(width, height), important)
}

export function sizeStyleFromObject(sz: { width: number | string; height: number | string }, important = false) {
	let out = ''
	for (const k in sz) {
		out += `
			${k}: ${padPx(sz[k])}${important ? ' !important' : ''};
		`
	}

	return out
}

export const padding = genericSizable('padding')
export const margin = genericSizable('margin')
export const borderRadius = genericCornerSizable('border-radius')
