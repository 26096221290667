import { useMediaQuery } from '@material-ui/core'
import { useCallback } from 'react'

import { useSessionStorage } from 'src/lib/react-hooks/useBrowserStorage'
import { FlightDirection, ProductType, SearchType, TripDirection } from 'src/travelsuit'

const newFlightSearchToggleKey = 'new-flight-search-experience'

export function isNewFlightSearchSupported(type: ProductType | SearchType, direction: FlightDirection) {
	return direction !== TripDirection.MultiDestination
}

export function getIsNewFlightSearchEnabled() {
	return sessionStorage.getItem(newFlightSearchToggleKey) === String(true)
}

export function setIsNewFlightSearchEnabled(isEnabled: boolean) {
	sessionStorage.setItem(newFlightSearchToggleKey, String(isEnabled))
}

export function useNewFlightSearchToggle() {
	const [value, setValue] = useSessionStorage(newFlightSearchToggleKey)
	const isMobile = useMediaQuery((t) => t.breakpoints.down('md'))
	const toggle = useCallback((value: boolean) => setValue(String(!isMobile && value)), [isMobile, setValue])

	// Disabled on mobile till the the flight search is not done
	return [!isMobile && value === String(true), toggle] as const
}
