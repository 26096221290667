import { ExpensesNewTypes } from 'src/redux/actions/actions.types'
import { denormalize, normalize } from 'src/redux/reducers/expenses-new.reducer'
import { Callback } from 'src/travelsuit'
import { Expense, ExpenseApi, QuickExpense } from 'src/travelsuit/expenses'

export const Actions = {
	getExpense(id: number, callback?: Callback<void, QuickExpense | Expense>) {
		return {
			type: ExpensesNewTypes.GetExpense.REQUEST,
			payload: { id },
			callback: (expense: ExpenseApi) => callback?.(normalize(expense)),
		}
	},
	getExpenses(callback?: Callback<void, Array<QuickExpense | Expense>>) {
		return {
			type: ExpensesNewTypes.GetExpenses.REQUEST,
			callback: (expenses: ExpenseApi[]) => callback?.(expenses.map(normalize)),
		}
	},
	deleteExpense(expense: QuickExpense | Expense, callback?: Callback, swapCallbackAndSuccessAction = false) {
		return {
			type: ExpensesNewTypes.DeleteExpense.REQUEST,
			payload: { id: expense.id },
			callback,
			swapCallbackAndSuccessAction,
		}
	},
	updateExpense(expense: Expense, callback?: Callback, swapCallbackAndSuccessAction = false) {
		const expenseApi = denormalize(expense)
		return {
			type: ExpensesNewTypes.UpdateExpense.REQUEST,
			payload: { id: expenseApi.id },
			body: expenseApi,
			callback,
			swapCallbackAndSuccessAction,
		}
	},
	createExpense(expense: Expense, callback?: Callback, swapCallbackAndSuccessAction = false) {
		return {
			type: ExpensesNewTypes.CreateExpense.REQUEST,
			body: denormalize(expense),
			callback,
			swapCallbackAndSuccessAction,
		}
	},
}
