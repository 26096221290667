import React from 'react'

import { countryFlag } from 'src/assets/countryFlags'
import SelectorButton from 'src/atoms/SelectorButton/SelectorButton'
import { Stack } from 'src/atoms/System'
import { getRegionFromLocale } from 'src/lib/locale-utils'
import { DefaultProps } from 'src/travelsuit'
import { Locales } from 'src/types/locale'

import { Flag, Label, Title } from './LanguageSelector.components'
import { useLanguageSelectorTranslation } from './useLanguageSelectorTranlation'

interface IProps extends DefaultProps {
	availableLanguages: Locales[]
	language: Locales
	onChange?(language: Locales, e: React.MouseEvent<HTMLElement>): void
}

export const LanguageSelector: React.FunctionComponent<IProps> = (props) => {
	const { className, language, onChange, availableLanguages } = props
	const translation = useLanguageSelectorTranslation()

	return (
		<Stack className={className} gap={2}>
			{availableLanguages.map((lang) => {
				const country = getRegionFromLocale(lang)
				return (
					<SelectorButton key={lang} selected={lang === language} onClick={(e) => onChange?.(lang, e)}>
						<Stack direction="row" gap={2} alignItems="center" justifyContent="flex-start">
							{country && <Flag src={countryFlag.svgUrl[country]} />}
							<div>
								<Title>{translation.title[lang]}</Title>
								<Label>{translation.label[lang]}</Label>
							</div>
						</Stack>
					</SelectorButton>
				)
			})}
		</Stack>
	)
}
