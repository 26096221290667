import { CancelToken } from 'axios'

export type AbortRequestConfigGetter = () => { cancelToken: CancelToken }

export interface WithAbortRequestConfig {
	getAbortRequestConfig?: AbortRequestConfigGetter
}

export enum LegacyJobStatus {
	Created = 'created',
	Failed = 'failed',
	Sent = 'sent',
	Success = 'success',
}
