import { EmptyVoidCallback, LoginDetails, SessionInfo, User, VoidCallback } from 'src/travelsuit'
import { UserId } from 'src/types'

import { IAction } from './action-helpers'
import { AuthTypes as Types } from './actions.types'

export const Actions = {
	internalLogin(callback?: VoidCallback<LoginDetails>, errCallback?: VoidCallback): IAction {
		return {
			type: Types.InternalLogin.REQUEST,
			callback,
			errCallback,
		}
	},
	finishLogin(authResult: auth0.Auth0DecodedHash, callback?: EmptyVoidCallback, errCallback?: VoidCallback): IAction {
		return {
			payload: authResult,
			type: Types.LoginDone,
			callback,
			errCallback,
		}
	},
	failLogin(): IAction {
		return {
			type: Types.LoginError,
		}
	},
	finishLogout(): IAction {
		return {
			type: Types.LogoutDone,
		}
	},
	failLogout(): IAction {
		return {
			type: Types.LogoutError,
		}
	},
	setInternalUser(user: User, loginData?: Omit<LoginDetails, 'user'>): IAction {
		return {
			payload: { user, loginData },
			type: Types.SetInternalUser,
		}
	},
	setTermsAccepted(accepted: boolean): IAction {
		return {
			payload: accepted,
			type: Types.SetTermsAccepted,
		}
	},
	getSessionInfo(callback?: VoidCallback<SessionInfo>, errCallback?: VoidCallback): IAction {
		return {
			type: Types.SessionInfo.REQUEST,
			callback,
			errCallback,
		}
	},
	updateSession(callback?: VoidCallback, errCallback?: VoidCallback): IAction {
		return {
			type: Types.UpdateSession.REQUEST,
			callback,
			errCallback,
		}
	},
	showModal() {
		return {
			type: Types.ShowModal,
		}
	},
	hideModal() {
		return {
			type: Types.HideModal,
		}
	},
	setIsMockEnabled(userId: UserId, value: boolean): IAction<{ userId: UserId; value: boolean }, any, User> {
		return {
			type: Types.SetIsMockEnabled.REQUEST,
			payload: { userId, value },
		}
	},
	setIsTokenizerMockEnabled(userId: UserId, value: boolean): IAction<{ userId: UserId; value: boolean }, any, User> {
		return {
			type: Types.SetIsTokenizerMockEnabled.REQUEST,
			payload: { userId, value },
		}
	},
	setIsWorldlineMockEnabled(userId: UserId, value: boolean): IAction<{ userId: UserId; value: boolean }, any, User> {
		return {
			type: Types.SetIsWorldlineMockEnabled.REQUEST,
			payload: { userId, value },
		}
	},
}
