import { appConfig } from 'src/app-config/appConfig'
import { createGetGoingAuthFailedResponseInterceptor, createGetGoingAuthRequestInterceptor } from 'src/lib/auth'

import { BaseApiClient } from '../BaseApiClient'

class GetGoingClient extends BaseApiClient {
	constructor() {
		super(appConfig.API_DOMAIN)

		this.client.interceptors.request.use(createGetGoingAuthRequestInterceptor())
		this.client.interceptors.response.use(undefined, createGetGoingAuthFailedResponseInterceptor(this.client))
	}
}

let client: GetGoingClient

export function getGGClient() {
	if (!client) {
		client = new GetGoingClient()
	}
	return client
}
