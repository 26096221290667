import { z } from 'zod'

export const AbsoluteURLZ = z.string().brand<'AbsoluteURL'>()

export type AbsoluteURL = z.infer<typeof AbsoluteURLZ>

export enum Currency {
	AUD = 'AUD',
	EUR = 'EUR',
	GBP = 'GBP',
	ILS = 'ILS',
	SEK = 'SEK',
	USD = 'USD',
}

export const CurrencyZ = z.nativeEnum(Currency)

export const EmailZ = z.string().email()

export type Email = z.infer<typeof EmailZ>

export const GenderZ = z.literal('male').or(z.literal('female'))

export type Gender = z.infer<typeof GenderZ>

export const RelativeURLZ = z.string().brand<'RelativeURL'>()

export type RelativeURL = z.infer<typeof RelativeURLZ>
