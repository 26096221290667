import { format } from 'date-fns/format'
import { isToday } from 'date-fns/isToday'
import moment from 'moment'

import { entityGenerator } from 'src/lib/utils'
import {
	AnySearchDetails,
	CabinClassNames,
	CarSearchDetails,
	CarSearchRequestDetails,
	FlightSearchDetails,
	FlightSearchRequestDetails,
	FlightSearchRouteDetails,
	FlightSearchRouteDetailsForRecent,
	HotelSearchDetails,
	HotelSearchRequestDetails,
	LocationTypes,
	ProductType,
	ShortDistanceUnits,
	TripDirection,
} from 'src/travelsuit'

const emptyFlightSearchRouteDetails = entityGenerator<FlightSearchRouteDetails>({
	from_location: null,
	to_location: null,
	departure_date: null,
	time_restriction: null,
	key: 'fragmentSegment',
	connecting_locations: null,
})

export const emptyHotelSearchDetails = entityGenerator<HotelSearchDetails>({
	near_location: null,
	from_date: null,
	until_date: null,
	radius: null,
	radius_units: ShortDistanceUnits.KM,
	include_non_traditional_properties: false,
})

export const emptyCarSearchDetails = entityGenerator<CarSearchDetails>({
	pickup_location: null,
	dropoff_location: null,
	from_date: null,
	until_date: null,
	pickup_location_type: null,
	dropoff_location_type: null,
})

export const emptyFlightSearchDetails = entityGenerator<FlightSearchDetails>({
	routes: [emptyFlightSearchRouteDetails()],
	flight_type: TripDirection.RoundTrip,
	cabin_class: CabinClassNames.economy,
	max_num_of_connections: 1,
	airline_alliances: [],
	airlines: [],
})

export function isFlightSearchRouteDetailsValid(route: FlightSearchRouteDetails) {
	return Boolean(route.departure_date && route.from_location && route.to_location)
}

export function isHotelSearchDetailsValid(searchDetails: HotelSearchDetails) {
	return Boolean(searchDetails.from_date && searchDetails.until_date && searchDetails.near_location)
}

const isRequeredCarsFieldFilled = ({
	searchDetails,
	requiredFieldNames,
}: {
	searchDetails: CarSearchDetails
	requiredFieldNames: string[]
}) => {
	return requiredFieldNames.every((key: string) => Boolean(searchDetails[key]))
}

export function hasDropoffLocation(searchDetails: CarSearchDetails) {
	return !!searchDetails.dropoff_location && searchDetails.dropoff_location.id !== searchDetails.pickup_location?.id
}

export function isCarSearchDetailsValid(searchDetails: CarSearchDetails) {
	const REQUIRED_FIELDS_FOR_CAR_SEARCH = ['from_date', 'until_date', 'pickup_location']

	const isValid = isRequeredCarsFieldFilled({ searchDetails, requiredFieldNames: REQUIRED_FIELDS_FOR_CAR_SEARCH })

	if (isValid && hasDropoffLocation(searchDetails)) {
		return isRequeredCarsFieldFilled({ searchDetails, requiredFieldNames: ['dropoff_location'] })
	}

	return isValid
}

const getTypeForFlightRoute = (
	route: FlightSearchRouteDetails | FlightSearchRouteDetailsForRecent,
	directionOfLocation: 'from' | 'to',
) => {
	const locationDetails = route[`${directionOfLocation}_location`]

	if (locationDetails && 'location_type' in locationDetails && locationDetails.location_type) {
		return locationDetails.location_type
	}
	if (locationDetails && 'airport_name' in locationDetails && !locationDetails.airport_name) {
		return LocationTypes.City
	}

	return null
}

function prepareFlightSearchDetailsJson({ airlines, ...details }: FlightSearchDetails): FlightSearchRequestDetails {
	return {
		...details,
		airline_codes: airlines?.map(({ iata }) => iata) ?? null,
		max_num_of_connections: details.max_num_of_connections ?? 2,
		cabin_class: details.cabin_class || CabinClassNames.economy,
		routes: details.routes.map((r: FlightSearchRouteDetails) => {
			return {
				from_location: r.from_location!.id,
				from_location_type: getTypeForFlightRoute(r, 'from'),
				to_location: r.to_location!.id,
				to_location_type: getTypeForFlightRoute(r, 'to'),

				departure_date: r.departure_date!,
				time_restriction: r.time_restriction ?? null,
				timezone_offset: new Date().getTimezoneOffset(),
				connecting_locations: (r.connecting_locations ?? []).map((locationDetails) => ({
					location_id: locationDetails.location.id!,
					location_type: locationDetails.location_type,
				})),
			}
		}),
	}
}

function prepareHotelSearchDetailsJson(details: HotelSearchDetails): HotelSearchRequestDetails {
	const nearLocationType =
		details.near_location!.location_type === LocationTypes.Office ? LocationTypes.Office : LocationTypes.Hotel

	return {
		...details,
		near_location_type: nearLocationType,
		near_location: details.near_location!.id,
		from_date: moment(details.from_date!).format(),
		until_date: moment(details.until_date!).format(),
	}
}

function prepareCarSearchDetailsJson(details: CarSearchDetails): CarSearchRequestDetails {
	return {
		...details,
		pickup_location: details.pickup_location!.id,
		dropoff_location: details.dropoff_location?.id || null,
		pickup_location_type: details.pickup_location_type || null,
		dropoff_location_type: details.dropoff_location_type || null,
	}
}

function prepareRailsSearchDetailsJson(details: FlightSearchDetails) {
	return {
		rails_type: details.flight_type,
		// TODO: BE contract needed
		//loyalty_card: details.loyalty_card,
		routes: details.routes.map((route) => {
			const isCurrentDate = isToday(new Date(route.departure_date!))
			const time = route.time_restriction?.time || (isCurrentDate ? format(new Date(), 'HH:mm:ss') : '05:00:00')
			return {
				departure_date: [route.departure_date, time].join('T'),
				from_location: route.from_location!.id,
				from_location_type: getTypeForFlightRoute(route, 'from'),
				to_location: route.to_location!.id,
				to_location_type: getTypeForFlightRoute(route, 'to'),
				connecting_locations: route.connecting_locations?.map((locationDetails) => ({
					location_id: locationDetails.location.id!,
					location_type: locationDetails.location_type,
				})),
			}
		}),
	}
}

export function prepareSearchDetailsForRequest(searchDetails: AnySearchDetails, searchType: ProductType) {
	switch (searchType) {
		case ProductType.Flights:
			return prepareFlightSearchDetailsJson(searchDetails as FlightSearchDetails)
		case ProductType.Rails:
			return prepareRailsSearchDetailsJson(searchDetails as FlightSearchDetails) as any
		case ProductType.Hotels:
			return prepareHotelSearchDetailsJson(searchDetails as HotelSearchDetails)
		case ProductType.Cars:
			return prepareCarSearchDetailsJson(searchDetails as CarSearchDetails)
	}
}
