import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useShowModal } from 'src/atoms/ModalApi'
import { useFetchData } from 'src/lib/react-hooks/useFetchData'
import { CreditCardResponse, InvoiceProfile } from 'src/travelsuit'
import { Fee, FeesData } from 'src/travelsuit/fees.types'

import BaseFeeCard from './BaseFeeCard'
import { FeeStatusBadge } from './FeeStatusBadge'
import PaymentError from './PaymentError'
import PaymentModal from './PaymentModal'
import usePayAgainFee from './usePayAgainFee'

interface FeeCardWithPaymentProps<T extends FeesData> {
	fee: Fee<T>
	onFeesReload: () => void
	onPaymentConfirm?: (creditCardId: number) => void
	invoiceProfile?: InvoiceProfile | null
	travelerName?: string
	primaryInfo: string
	secondaryChipText?: string
	paymentModalTitle: string
	canChangeDefaultCard?: boolean
	displayInvoiceProfileNameForSubscription?: boolean
	feeDetails?: React.ReactNode
	statusActions?: React.ReactNode
	isPartiallyRefunded?: boolean
	additionalPriceDetails?: { label: string; amount: number }[]
}

export function FeeCardWithPayment<T extends FeesData>({
	fee,
	onFeesReload,
	onPaymentConfirm,
	invoiceProfile,
	primaryInfo,
	travelerName,
	secondaryChipText,
	paymentModalTitle,
	canChangeDefaultCard,
	displayInvoiceProfileNameForSubscription,
	feeDetails,
	statusActions,
	isPartiallyRefunded,
	additionalPriceDetails,
}: FeeCardWithPaymentProps<T>) {
	const { t } = useTranslation()
	const [isPayAgainModalOpen, setPayAgainModalOpen] = useState(false)
	const [creditCards, setCreditCards] = useState<CreditCardResponse[]>([])

	const show = useShowModal()

	const { handlePaymentRequest, isRequestInProgress } = usePayAgainFee(onFeesReload)

	const { fetchData } = useFetchData<CreditCardResponse[]>()

	const { credit_card, id: feeId, status } = fee[fee.service_type]

	const openPayAgainModal = async () => {
		const { data: cards } = await fetchData({ resourcePath: `fee/${feeId}/credit_cards` })
		setCreditCards(cards)
		setPayAgainModalOpen(true)
	}

	const closePayAgainModal = () => setPayAgainModalOpen(false)

	const onConfirm = async (creditCardId: number, isCardDefault?: boolean) => {
		const shouldHandleDefaultCardChange = canChangeDefaultCard && isCardDefault
		if (shouldHandleDefaultCardChange) {
			onPaymentConfirm?.(creditCardId)
		}

		try {
			await handlePaymentRequest(feeId, creditCardId!)
		} catch (error) {
			if (shouldHandleDefaultCardChange) {
				onFeesReload?.()
			}
			show(({ close }) => <PaymentError onClose={close} error={error} />)
		}
		closePayAgainModal()
	}

	return (
		<>
			<BaseFeeCard
				fee={fee}
				country={invoiceProfile?.country}
				secondaryChipText={secondaryChipText}
				primaryInfo={primaryInfo}
				invoiceProfileName={invoiceProfile?.display_name || ''}
				traveler={travelerName}
				status={<FeeStatusBadge status={status} isPartiallyRefunded={isPartiallyRefunded} />}
				onPayAgainClick={openPayAgainModal}
				displayInvoiceProfileNameForSubscription={displayInvoiceProfileNameForSubscription}
				feeDetails={feeDetails}
				statusActions={statusActions}
				additionalPriceDetails={additionalPriceDetails}
			/>
			{isPayAgainModalOpen && (
				<PaymentModal
					title={paymentModalTitle}
					subtitle={t('usage-details.pay-again-modal.select-card', 'Please select credit cards and try again.')}
					onClose={closePayAgainModal}
					onConfirm={onConfirm}
					isLoading={isRequestInProgress}
					defaultInvoiceProfile={invoiceProfile}
					creditCards={creditCards}
					defaultCreditCard={credit_card}
					canChangeDefaultCard={canChangeDefaultCard}
				/>
			)}
		</>
	)
}
