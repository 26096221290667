import React from 'react'

import styled from 'src/styles'

import { MobileDialogView } from './MobileDialogView'

export const StyledMobileDialogView = styled(MobileDialogView)`
	padding-left: ${(p) => p.theme.spacing(3)}px;
	padding-right: ${(p) => p.theme.spacing(3)}px;
	padding-bottom: ${(p) => p.theme.spacing(3)}px;

	overflow-y: auto;
`

export interface MobileDialogProps {
	open?: boolean
	onClose?(e?: React.MouseEvent<any>): void
	shouldUseArrowCloseButton?: boolean
	fullHeight?: boolean
	onOpen?(): void
}

export const MobileDialog: React.FC<MobileDialogProps> = ({ open = true, children, fullHeight = true, ...rest }) => {
	return (
		<>
			{open ? (
				<StyledMobileDialogView open={open} fullHeight={fullHeight} {...rest}>
					{children}
				</StyledMobileDialogView>
			) : (
				children
			)}
		</>
	)
}
