export function mergeMapsValues<K, V>(target: Map<K, V>, source?: Map<K, V>) {
	if (!source) {
		return target
	}

	const cloned = new Map(target)

	for (const [key, value] of source.entries()) {
		const valueFromTartget = cloned.get(key)
		cloned.set(key, { ...valueFromTartget, ...value })
	}

	return cloned
}

export function createMapFromArray<T, K, V>(items: T[], getKey: (item: T) => K, getValue: (item: T) => V) {
	return new Map(items.map((item) => [getKey(item), getValue(item)]))
}

export function mapGetOrSet<K, V>(map: Map<K, V>, key: K, valueFactory: (key: K) => V): V {
	if (!map.has(key)) {
		map.set(key, valueFactory(key))
	}
	return map.get(key) as V
}
