import { z } from 'zod'

import { AbsoluteURLZ } from 'src/types/common'
import { UserZ } from 'src/types/user'

export const CreditCardBillingDataZ = z.object({
	first_name: z.string().nullish(),
	last_name: z.string().nullish(),
	email: z.string().nullish(),
	phone_number: z.string().nullish(),
	street: z.string().nullish(),
	number: z.string().nullish(),
	postal_code: z.string().nullish(),
	city: z.string().nullish(),
	country: z.string().nullish(),
	building: z.string().nullish(),
	room: z.string().nullish(),
	floor: z.string().nullish(),
	district: z.string().nullish(),
	state: z.string().nullish(),
})

export type CreditCardBillingData = z.infer<typeof CreditCardBillingDataZ>

export const CreditCardIdZ = z.number() // TODO: restore the brand after Expense forms get sorted out .brand<'CreditCardId'>()

export type CreditCardId = z.infer<typeof CreditCardIdZ>

export const CreditCardOwnerTypeZ = z.union([z.literal('company'), z.literal('traveler')])

export type CreditCardOwnerType = z.infer<typeof CreditCardOwnerTypeZ>

export enum UsageAllowedFor {
	Country = 'country',
	InvoiceProfile = 'invoice_profile',
}

export const UsageAllowedForZ = z.nativeEnum(UsageAllowedFor)

export enum CreditCardStatus {
	Active = 'active',
	Draft = 'draft',
	Failed = 'failed',
	Invalid = 'invalid',
	InvalidAirPlus = 'invalid_airplus',
}

export const CreditCardStatusZ = z.nativeEnum(CreditCardStatus)

export enum CreditCardType {
	AirPlus = 'air-plus',
	AmericanExpress = 'amex',
	AmexBTA = 'amex-bta',
	AmexBTAGermany = 'amex-bta-germany',
	Diners = 'diners',
	MasterCard = 'master-card',
	Other = 'other',
	Visa = 'visa',
}

export const CreditCardTypeZ = z.nativeEnum(CreditCardType)

export const CreditCardPermissionsZ = z.object({
	usage_allowed_for: UsageAllowedForZ.nullable(),
	available_only_for_travel_arrangers: z.boolean(),
	permitted_for_flights: z.boolean(),
	permitted_for_hotels: z.boolean(),
	permitted_for_cars: z.boolean(),
	permitted_for_fees: z.boolean(),
})

export type CreditCardPermissions = z.infer<typeof CreditCardPermissionsZ>

export const CreditCardZ = z.object({
	card_data: CreditCardBillingDataZ.nullish(),
	card_expiration_date: z.string(),
	card_type: CreditCardTypeZ,
	id: CreditCardIdZ,
	card_holder_name: z.string(),
	card_number: z.string(),
	allow_use_my_card: z.boolean(),
	card_name: z.string(),
	related_to: CreditCardOwnerTypeZ,
	cvc_exist: z.boolean().nullable(),
	status: CreditCardStatusZ,
	used_for_package_payment: z.boolean().nullable(),
	cost_center: z.boolean().nullish(),
	employee_id: z.boolean().nullish(),
	department: z.boolean().nullish(),
	accounting_unit: z.boolean().nullish(),
	action_code: z.boolean().nullish(),
	purchase_order_number: z.boolean().nullish(),
	project_code: z.boolean().nullish(),
	default_for_fees: z.boolean().nullish(),
	invoice_profile: z.object({ id: z.number(), display_name: z.string() }).nullish(),
	user: UserZ.pick({
		id: true,
		first_name: true,
		last_name: true,
		middle_name: true,
	}).nullish(),
	...CreditCardPermissionsZ.shape,
})

export type CreditCard = z.infer<typeof CreditCardZ>

export const GetCreditCardStatusResponseZ = z.object({
	status: CreditCardStatusZ,
	worldline_verification_url: AbsoluteURLZ.nullable(),
})

export type GetCreditCardStatusResponse = z.infer<typeof GetCreditCardStatusResponseZ>
