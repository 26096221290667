import { z } from 'zod'

import { AbsoluteURLZ } from '../common'

export enum UTAAssessment {
	Benefit = 'benefit',
	Fee = 'fee',
	Restriction = 'restriction',
}

export const UTAAssessmentZ = z.nativeEnum(UTAAssessment)

export enum UTAName {
	AdvanceChange = 'advance-change',
	Cancellation = 'cancellation',
	CarryOnAllowance = 'carry-on-allowance',
	CheckedBagAllowance = 'checked-bag-allowance',
	SeatSelection = 'seat-selection',
	UpgradeEligibility = 'upgrade-eligibility',
}

export const UTANameZ = z.nativeEnum(UTAName)

export const UTABagLimitZ = z.object({
	size_lcm: z.number().nullable(),
	weight_kg: z.number(),
})

export type UTABagLimit = z.infer<typeof UTABagLimitZ>

export const UTAFeeZ = z.object({
	amount: z.number(),
	amount_max: z.number().nullable(),
	amount_min: z.number().nullable(),
	currency: z.string(),
})

export type UTAFee = z.infer<typeof UTAFeeZ>

export const UTAZ = z.object({
	assessment: UTAAssessmentZ,
	bag_limits: UTABagLimitZ.nullable(),
	description: z.string(),
	fees: z.array(UTAFeeZ).nullable(),
	headline: z.string(),
	is_allowed: z.boolean(),
	large_icon_url: AbsoluteURLZ.nullable(),
	name: UTANameZ,
})

export type UTA = z.infer<typeof UTAZ>

export const FlightOptionUTAZ = z.array(UTAZ).nullable()

export type FlightOptionUTA = z.infer<typeof FlightOptionUTAZ>

export const BookingOptionUTAZ = z.array(FlightOptionUTAZ).nullable()

export type BookingOptionUTA = z.infer<typeof BookingOptionUTAZ>
