import moment from 'moment'

import { replaceEntityById } from 'src/lib/entity/replaceEntityById'
import { ExpensesNewTypes, IAction } from 'src/redux/actions'
import { Expense, ExpenseApi, File, QuickExpense, QuickExpenseApi } from 'src/travelsuit/expenses'

export type ExpensesNewState = Array<QuickExpense | Expense>

function expensesNewReducer(state: ExpensesNewState = [], action: IAction) {
	switch (action.type) {
		case ExpensesNewTypes.GetExpense.SUCCESS: {
			return replaceEntityById(state, normalize(action.payload))
		}
		case ExpensesNewTypes.GetExpenses.SUCCESS:
			return action.payload.map(normalize)
		default:
			return state
	}
}

export default expensesNewReducer

export function normalize(expense: QuickExpenseApi | ExpenseApi): QuickExpense | Expense {
	if (expense.expense_type !== 'expense') {
		throw new Error('Only expenses are supported')
	}

	const normalized = [
		'id',
		'submitter',
		'merchant',
		'date',
		'category',
		'amount',
		'currency',
		'attendees',
		'description',
	].reduce(
		(acc, key) =>
			expense[key]
				? {
						...acc,
						[key]: expense[key],
					}
				: acc,
		{},
	) as QuickExpense | Expense

	normalized.type = expense.expense_type
	normalized.status = expense.expense_status
	if ('ocr_status' in expense && expense.ocr_status) {
		normalized.ocr_status = expense.ocr_status
	}
	if ('ocr_results' in expense && expense.ocr_results) {
		normalized.ocr_results = expense.ocr_results
	}
	normalized.created_at = new Date(expense.created_dt)
	normalized.file = expense.file_data as File

	if ('expense_report' in expense && expense.expense_report && expense.report_id) {
		normalized.report = { id: expense.report_id, name: expense.expense_report }
	}

	return normalized
}

export function denormalize(expense: Expense): ExpenseApi {
	if (expense.type !== 'expense') {
		throw new Error('Only expenses are supported')
	}

	const denormalized = [
		'id',
		'merchant',
		'category',
		'amount',
		'currency',
		'description',
		'ocr_status',
		'ocr_results',
	].reduce(
		(acc, key) =>
			expense[key]
				? {
						...acc,
						[key]: expense[key],
					}
				: acc,
		{},
	) as ExpenseApi

	denormalized.created_dt = expense.created_at.toString()
	denormalized.date = expense.date.toString()

	if (expense.file) {
		denormalized.receipt_url = expense.file.url ?? ''
		denormalized.file_data = { name: expense.file.name ?? '', size: expense.file.size ?? 0 }
	}
	denormalized.expense_type = 'expense'
	denormalized.expense_status = expense.status
	denormalized.date = moment(expense.date).format('YYYY-MM-DD')
	denormalized.attendees = expense.attendees.map((attendee) => {
		return attendee.id ? { id: attendee.id } : attendee
	})
	denormalized.expense_report = expense.report?.name || ''
	denormalized.submitter_id = expense.submitter.id

	return denormalized
}
