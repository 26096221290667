import React from 'react'

import CircularLoader from 'src/atoms/Loader'
import styled, { animations } from 'src/styles'

const StyledLoader = styled(CircularLoader)`
	${animations.fadeIn.animate(2000)}
`

export function LoadingIndicator() {
	return <StyledLoader size={20} desktopSize={64} />
}
