import React from 'react'

import PolicyCarImg from 'src/assets/policy/car-policy.svg'
import PolicyFlightImg from 'src/assets/policy/flight-policy.svg'
import PolicyGeneralImg from 'src/assets/policy/general-policy.svg'
import PolicyHotelImg from 'src/assets/policy/hotel-policy.svg'
import Drawer, { IProps as DrawerProps } from 'src/atoms/Drawer/Drawer'
import { Flex, Hr, Link, SizedBox } from 'src/atoms/GenericComponents/GenericComponents'
import InfoBox from 'src/atoms/InfoBox/InfoBox'
import { CircularLoader } from 'src/atoms/Loader/Loader'
import SvgIcon from 'src/atoms/SvgIcon/SvgIcon'
import Tab from 'src/atoms/Tab/Tab'
import Tabs, { TabContent, TabStrip } from 'src/atoms/Tabs/Tabs'
import WizardPage, { ContentContainer } from 'src/atoms/WizardPage/WizardPage'
import { useTranslation } from 'src/lib/i18n/i18n'
import { useLoadingSelector, useUser } from 'src/lib/react-hooks/generic-hooks'
import { routeFor, Routes } from 'src/lib/route-utils'
import { isAdminUser } from 'src/lib/user-utils'
import { eventStopper, unique } from 'src/lib/utils'
import Wizard from 'src/molecules/Wizard/Wizard'
import { TravelPolicyTypes } from 'src/redux/actions'
import Edit from 'src/refactor/assets/actions/edit.svg'
import ShieldIcon from 'src/refactor/assets/icons/shield.svg'
import { font } from 'src/refactor/fonts'
import styled from 'src/styles'
import { DefaultElProps, getPolicyTypeLabel, GroupTypes, PolicyType, ProductType, User } from 'src/travelsuit'
import { track } from 'src/travelsuit/analytics'
import { TripTravelPolicy } from 'src/travelsuit/policy'
import { fullName } from 'src/travelsuit/users'

import CarPolicySettingsSummary from '../PolicySettings/CarPolicySettingsSummary'
import FlightPolicySettingsSummary from '../PolicySettings/FlightPolicySettingsSummary'
import GeneralPolicySettingsSummary from '../PolicySettings/GeneralPolicySettingsSummary'
import HotelPolicySettingsSummary from '../PolicySettings/HotelPolicySettingsSummary'
import { PolicySettingSummary } from '../PolicySettings/PolicySettings.components'
import PreTripSettingsSummary from '../PolicySettings/PreTripSettingsSummary'

interface IProps extends DefaultElProps<'div'>, Omit<DrawerProps, 'ref'> {
	policies: TripTravelPolicy[]
	productType?: ProductType
}

const PolicyImg = styled.img`
	width: 100%;
`

const StyledTabs = styled(Tabs).attrs(() => ({
	disableRouting: true,
	alignment: 'left',
}))<{ hideTabStrip?: boolean }>`
	margin: -${(p) => p.theme.spacing(9)}px -${(p) => p.theme.spacing(8)}px 0;

	& ${Tab} {
		min-width: 100px;
		text-align: center;
	}

	${(p) => p.theme.breakpoints.up('lg')} {
		& ${TabStrip} {
			padding-left: ${(p) => p.theme.spacing(6)}px;
		}
	}

	& ${TabContent} {
		padding: ${(p) => p.theme.spacing(4)}px ${(p) => p.theme.spacing(8)}px;
	}

	${(p) => p.theme.breakpoints.down('md')} {
		margin: -${(p) => p.theme.spacing(5)}px 0 0;

		${TabStrip} {
			margin: 0 -${(p) => p.theme.spacing(3)}px;
			box-shadow: none;
		}

		${TabContent} {
			padding: 0;
			padding-top: ${(p) => p.theme.spacing(3)}px;
		}
	}

	${(props) =>
		props.hideTabStrip
			? `
				& ${TabStrip} {
					display: none;
				}
			`
			: ''}
`

const PolicyContentContainer = styled.div`
	${(p) => p.theme.breakpoints.down('md')} {
		${PolicySettingSummary} {
			padding: 0;
		}
	}

	${(p) => p.theme.breakpoints.up('lg')} {
		margin: ${(p) => p.theme.spacing(6)}px -${(p) => p.theme.spacing(6)}px 0;
	}
`

const StyledHr = styled(Hr)`
	margin: ${(p) => p.theme.spacing(3)}px 0;

	${(p) => p.theme.breakpoints.up('lg')} {
		display: none;
	}
`

const StyledWizardPage = styled(WizardPage)`
	& ${ContentContainer} {
		overflow-x: hidden;
	}
`

const PolicyNameContainer = styled(Flex).attrs(() => ({
	justify: 'flex-start',
	align: 'center',
}))`
	${font({ size: 16, weight: 'bold' })}
`

const StyledSizedBox = styled(SizedBox)`
	:first-child {
		display: none;
	}
`

const imageByType: Record<PolicyType, string | null> = {
	[PolicyType.Flight]: PolicyFlightImg,
	[PolicyType.Hotel]: PolicyHotelImg,
	[PolicyType.Car]: PolicyCarImg,
	[PolicyType.General]: PolicyGeneralImg,
	[PolicyType.PreTrip]: PolicyGeneralImg,
}

const PolicyPreviewSidebar: React.FunctionComponent<IProps> = track({})((props) => {
	const { className, policies, productType, onClick, ...rest } = props
	const { onClose } = rest
	const { t } = useTranslation()
	const loading = useLoadingSelector(TravelPolicyTypes.GetPolicyForUsers)
	const my = useUser()

	const availableTypes = getAvailableTypes(policies)
	const firstAvailableType = getFirstAvailableType(policies, productType)
	const users = unique(
		policies.map((p) => p.user),
		(u) => u.id,
	)

	const [selectedUserId, setSelectedUserId] = React.useState(getFirstUserId(policies, my))
	const [policyType, setPolicyType] = React.useState(firstAvailableType!)
	const isAdmin = isAdminUser()

	React.useEffect(() => {
		setSelectedUserId(getFirstUserId(policies, my))
		setPolicyType(getFirstAvailableType(policies, productType)!)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [users.map((u) => u.id).join(',')])

	return (
		<Drawer className={className} onClick={eventStopper.stopPropagation(onClick)} {...rest}>
			<Wizard onWizardFinish={rest.onClose}>
				<StyledWizardPage
					title={
						users?.length !== 1
							? t('policy-preview-sidebar.title-no-traveler', 'Travel Policy')
							: t('policy-preview-sidebar.title-traveler', 'Travel Policy for {{name}}', { name: fullName(users[0]) })
					}
					proceedButtonProps={{ label: t('policy-preview-sidebar.button', 'Close') }}
					onProceed={onClose}
				>
					{loading ? (
						<CircularLoader color="primary" size={30} />
					) : (
						<>
							<StyledTabs
								active={policyType}
								onChange={(k: PolicyType) => setPolicyType(k)}
								hideTabStrip={availableTypes.length < 2}
							>
								{availableTypes.map((type, i) => (
									<Tab key={i} tabKey={type} label={getPolicyTypeLabel({ policyType: type, t })}>
										{users.length > 1 && (
											<InfoBox level="error">
												{t(
													'policy-preview-sidebar.multi-policy-disclaimer',
													'When planning a trip for travelers with different policies, the more restrictive policy will ' +
														'be applied.',
												)}
											</InfoBox>
										)}
										<StyledSizedBox height={15} />
										{imageByType[policyType] !== null && <PolicyImg src={imageByType[policyType]!} />}
									</Tab>
								))}
							</StyledTabs>
							<SizedBox height={20} />
							<StyledTabs
								active={String(selectedUserId)}
								onChange={(id) => setSelectedUserId(Number(id))}
								hideTabStrip={users.length < 2}
							>
								{policies.map((policy, i) => (
									<Tab key={i} tabKey={String(policy.user.id)} label={fullName(policy.user)}>
										<Flex align="center" justify="space-between">
											<PolicyNameContainer>
												<SvgIcon src={ShieldIcon} middle />
												<SizedBox as="span" width={10} />
												{t('policy-preview-sidebar.policy-name', 'Policy: {{policy}}', {
													policy: policy.policy.group.name,
												})}
											</PolicyNameContainer>
											{isAdmin && (
												<Link
													href={routeFor(Routes.ManagePolicy, {
														type: policy.policy.group.type.replace('_', '-'),
														id: policy.policy.group.type === GroupTypes.Company ? undefined : policy.policy.group_id,
														section: policyType?.replace('_', '-'),
													})}
												>
													<SvgIcon src={Edit} middle />
													<SizedBox as="span" width={10} />
													{t('policy-preview-sidebar.edit-policy', 'Edit this policy')}
												</Link>
											)}
										</Flex>
										<StyledHr />
										<PolicyContentContainer>{getSummaryByType(policy, policyType)}</PolicyContentContainer>
									</Tab>
								))}
							</StyledTabs>
						</>
					)}
				</StyledWizardPage>
			</Wizard>
		</Drawer>
	)
})

function getSummaryByType(policy: TripTravelPolicy, policyType: PolicyType) {
	switch (policyType) {
		case PolicyType.Flight:
			return (
				<FlightPolicySettingsSummary
					columns={1}
					capRoutesRowsLimit={0}
					policy={policy.policy}
					role={policy.user_role}
				/>
			)
		case PolicyType.Hotel:
			return (
				<HotelPolicySettingsSummary columns={1} capRoutesRowsLimit={0} policy={policy.policy} role={policy.user_role} />
			)
		case PolicyType.Car:
			return <CarPolicySettingsSummary columns={1} policy={policy.policy} role={policy.user_role} />
		case PolicyType.General:
			return (
				<GeneralPolicySettingsSummary
					columns={1}
					policy={policy.policy}
					actualApprovers={policy.actual_approvers}
					ignoreApprovals={policy.ignore_approval}
				/>
			)
		case PolicyType.PreTrip:
			return <PreTripSettingsSummary columns={1} policy={policy.policy} showDisclaimer={false} />
	}
}

function getAvailableTypes(policies: TripTravelPolicy[]): PolicyType[] {
	const types = new Set<PolicyType>()

	for (const policy of policies) {
		const typeMapping: Record<PolicyType, any> = {
			[PolicyType.Flight]: policy.policy.flight_policy_data,
			[PolicyType.Hotel]: policy.policy.hotel_policy_data,
			[PolicyType.Car]: policy.policy.car_policy_data,
			[PolicyType.PreTrip]: policy.policy.pre_trip_policy_data?.enabled,
			[PolicyType.General]: policy.policy.approval_policy || policy.policy.approvers.length,
		}

		for (const type in typeMapping) {
			if (typeMapping[type]) {
				types.add(type as PolicyType)
			}
		}
	}

	return Array.from(types)
}

function getFirstAvailableType(policies: TripTravelPolicy[], productType?: ProductType): PolicyType | null {
	const availableTypes = getAvailableTypes(policies)

	if (productType) {
		const _map: Partial<Record<ProductType, PolicyType>> = {
			[ProductType.Flights]: PolicyType.Flight,
			[ProductType.Hotels]: PolicyType.Hotel,
			[ProductType.Cars]: PolicyType.Car,
		}

		if (_map[productType]) {
			return _map[productType] as PolicyType
		}
	}

	if (availableTypes.length) {
		return availableTypes[0]
	}
	return null
}

function getFirstUserId(policies: TripTravelPolicy[], my: User): number | null {
	if (!policies?.length) {
		return null
	}

	if (policies.find((p) => p.user.id === my.id)) {
		return my.id!
	}

	return policies[0].user.id!
}

export default PolicyPreviewSidebar
