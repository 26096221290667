import { filterEmptyValues } from 'src/lib/array-utils'
import { Location, LocationForRecentSearch, LocationTypes } from 'src/travelsuit'

export function getLocationName(location: Location | LocationForRecentSearch) {
	const locationType = 'location_type' in location ? location.location_type : undefined

	return filterEmptyValues([
		location.name,
		locationType && [LocationTypes.Airport, LocationTypes.City].includes(locationType)
			? location.country_name
			: undefined,
	]).join(', ')
}
