import { Company } from 'src/travelsuit'
import { CompanyZ } from 'src/types/company'

import { getGGClient } from './GetGoingClient'

export async function getMyCompany() {
	return getGGClient()
		.request('GET', `companies`, {
			responseSchema: CompanyZ,
		})
		.then((r) => r.data as Company)
}
