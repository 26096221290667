import '@babel/polyfill'
import { ThemeProvider } from '@material-ui/core/styles'
import { StylesProvider } from '@material-ui/core/styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// Moment.js locales
// German locale
import 'moment/locale/de.js'
// Eng-Israel locale
import 'moment/locale/en-gb.js'
import 'raf/polyfill'
import 'rc-progress/assets/index.css'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { Userpilot } from 'userpilot'

import { createApplicationStore } from 'src/redux/stores'
import { GlobalStyle, theme } from 'src/styles'

import { appConfig } from './app-config/appConfig'
import { ErrorBoundary, ErrorTrackingBoundary, initializeErrorTracking } from './error-tracking'
import { initializeI18n } from './i18nConfig'
import './index.module.css'
import { handleUserReloadRequest, initializeAuthProvider, tryHashQueryLogin } from './lib/auth'
import { isMobile } from './lib/browser'
import { ENV } from './lib/env'
import { IOSEventType, isLaunchedAsIOSApp, subscribeForIOSEvent } from './lib/ios'
import { redirectTo, Routes } from './lib/route-utils'
import { noop } from './lib/utils'
import App from './pages/App/App'
import { GeneralErrorPage } from './pages/GeneralErrorPage'
import './polyfills'
import { registerServiceWorker } from './pwa/registerServiceWorker'
import { createDefaultState } from './redux/stores/createDefaultState'
import { UserTrackingClient } from './tracking/types'
import { UserTrackingContext } from './tracking/UserTrackingContext'

if (!ENV.IS_DEBUG) {
	window.console['debug'] = noop
}

function wrapWithErrorBoundary(node: JSX.Element): JSX.Element {
	if (!appConfig.SENTRY_ENABLED) {
		return <ErrorBoundary fallback={<GeneralErrorPage errorId="" />}>{node}</ErrorBoundary>
	}

	return (
		<ErrorTrackingBoundary fallback={({ eventId }) => <GeneralErrorPage errorId={eventId} />}>
			{node}
		</ErrorTrackingBoundary>
	)
}

document.addEventListener('DOMContentLoaded', async () => {
	if (appConfig.SENTRY_ENABLED) {
		initializeErrorTracking()
	}
	const i18nPromise = initializeI18n()
	initializeAuthProvider()

	if ('serviceWorker' in navigator) {
		registerServiceWorker()
	}

	if (isLaunchedAsIOSApp()) {
		subscribeForIOSEvent(IOSEventType.RedirectToTrip, ({ detail }) => {
			redirectTo(Routes.Itinerary, { id: Number(detail) })
		})
	}

	const googleMapsScriptElement = document.createElement('script')
	googleMapsScriptElement.id = 'google-maps'
	googleMapsScriptElement.async = true
	googleMapsScriptElement.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${appConfig.PLACES_API_KEY}&language=en`
	document.body.appendChild(googleMapsScriptElement)

	const trackingClient: UserTrackingClient | null = appConfig.USERPILOT_KEY
		? { reload: Userpilot.reload, identify: (userId, meta) => Userpilot.identify(String(userId), meta) }
		: null

	if (appConfig.USERPILOT_KEY) {
		Userpilot.initialize(appConfig.USERPILOT_KEY)
	}

	const store = createApplicationStore(createDefaultState())

	await tryHashQueryLogin()

	await handleUserReloadRequest()

	await i18nPromise

	const queryClient = new QueryClient()

	render(
		<ThemeProvider theme={theme}>
			<StylesProvider injectFirst>
				<StyledThemeProvider theme={theme}>
					<GlobalStyle />
					{wrapWithErrorBoundary(
						<UserTrackingContext.Provider value={trackingClient}>
							<Provider store={store}>
								<QueryClientProvider client={queryClient}>
									<App />
								</QueryClientProvider>
							</Provider>
						</UserTrackingContext.Provider>,
					)}
				</StyledThemeProvider>
			</StylesProvider>
		</ThemeProvider>,
		document.getElementById('root'),
	)

	const _w: any = window
	if (isMobile() && _w.Intercom) {
		_w.Intercom('update', {
			hide_default_launcher: true,
		})
	}
})
