import React from 'react'
import { useTranslation } from 'react-i18next'

import { white } from 'src/_vars'
import Confirmation from 'src/atoms/Confirmation/Confirmation'
import { Stack } from 'src/atoms/System'
import { getShortYearDateFormat, getYearDateFormat } from 'src/lib/format/dateTimeFormat'
import { currency } from 'src/lib/number-utils'
import { StatusBadge } from 'src/molecules/FeeCards/FeeStatusBadge'
import { PriceContainer, UserDetailsCollapse, UserDetailsTitleContent } from 'src/molecules/ReportDetails'
import { UserSelect, useUserSelect } from 'src/molecules/UserSelect'
import { brightRed } from 'src/refactor/colors'
import { fullName } from 'src/travelsuit/users.utils'
import { ActiveUserFeeDetails } from 'src/types/usageDetails'

import { ActiveUserFeeReportDetailSection } from './ActiveUserFeeReportDetailSection'

interface ActiveUserFeeDetailsModalProps {
	activeUserFeeDetails: ActiveUserFeeDetails
	onClose: () => void
	startDate: Date
	endDate: Date
}

export function ActiveUserFeeDetailsModal({
	activeUserFeeDetails,
	onClose,
	startDate,
	endDate,
}: ActiveUserFeeDetailsModalProps) {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const users = React.useMemo(() => {
		return activeUserFeeDetails.map(({ user }) => user)
	}, [activeUserFeeDetails])

	const { toggleAll, selectedUserIds, setSelectedUserIds, onReset } = useUserSelect({
		users,
	})

	const dateFormatter = getYearDateFormat(language)

	return (
		<Confirmation
			open
			title={t('active-user-fee-details.modal.title', 'Active User Fee Details')}
			onOK={onClose}
			showCancelButton={false}
		>
			<Stack gap={6}>
				<h5>
					{t(
						'active-user-fee-details.modal.header',
						'Here you can see information about active user fees created in the billing period of {{startDate}} - {{endDate}}',
						{ startDate: dateFormatter.format(startDate), endDate: dateFormatter.format(endDate) },
					)}
				</h5>
				<UserSelect
					users={users}
					selectedUserIds={selectedUserIds}
					onChange={setSelectedUserIds}
					toggleAll={toggleAll}
					onReset={onReset}
				/>
				{activeUserFeeDetails
					.filter(({ user }) => selectedUserIds.includes(user.id))
					.map((details) => {
						return (
							<UserDetailsCollapse
								key={details.user.id}
								title={
									<UserDetailsTitleContent>
										{fullName(details.user)}
										<PriceContainer>
											<b>{currency(details.total_price, { currency: details.currency })}</b>
											{details.refunded_date ? (
												<StatusBadge bgColor={brightRed} textColor={white}>
													{t('usage-details.refunded-on', 'Refunded on {{date}}', {
														date: getShortYearDateFormat(language).format(new Date(details.refunded_date)),
													})}
												</StatusBadge>
											) : null}
										</PriceContainer>
									</UserDetailsTitleContent>
								}
							>
								<ActiveUserFeeReportDetailSection activeUserFeeReportDetail={details} />
							</UserDetailsCollapse>
						)
					})}
			</Stack>
		</Confirmation>
	)
}
