import { z } from 'zod'

import { RequestOverrides } from 'src/clients/BaseApiClient'
import { CreditCardResponse } from 'src/travelsuit'
import { TripMockMetadataZ } from 'src/types'
import { CreditCardId, CreditCardZ, GetCreditCardStatusResponseZ } from 'src/types/creditCard'

import { getGGClient } from './GetGoingClient'

export async function deleteCreditCard(creditCardId: CreditCardId, overrides?: RequestOverrides) {
	await getGGClient().request('DELETE', `credit_card/${creditCardId}`, overrides)
}

export async function getCreditCard(creditCardId: CreditCardId, overrides?: RequestOverrides) {
	return getGGClient()
		.request('GET', `credit_card/${creditCardId}`, {
			responseSchema: CreditCardZ,
			...overrides,
		})
		.then((r) => r.data as CreditCardResponse)
}

getCreditCard.queryKey = (creditCardId: CreditCardId) => ['creditCard', { creditCardId }] as const

export async function getCreditCardStatus(creditCardId: CreditCardId, overrides?: RequestOverrides) {
	return getGGClient()
		.request('GET', `credit_card/${creditCardId}/status`, {
			responseSchema: GetCreditCardStatusResponseZ,
			...overrides,
		})
		.then((r) => r.data)
}

export async function getCreditCardMockOptions(overrides?: RequestOverrides) {
	return getGGClient()
		.request('GET', `trips/mock/metadata`, {
			responseSchema: TripMockMetadataZ,
			...overrides,
		})
		.then((r) =>
			Object.fromEntries(
				Object.entries(r.data.mock_options).filter(([key]) =>
					['airplus_', 'tokenizer_', 'worldline_'].some((prefix) => key.startsWith(prefix)),
				),
			),
		)
}

getCreditCardMockOptions.queryKey = ['creditCard', 'mocks', 'metadata'] as const

export async function setCreditCardMock(creditCardId: CreditCardId, mock: string | null, overrides?: RequestOverrides) {
	await getGGClient().request('POST', `credit_card/${creditCardId}/mock`, {
		requestSchema: z.object({ mock: z.string().nullable() }),
		data: { mock },
		...overrides,
	})
}
