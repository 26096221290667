/* eslint-disable no-process-env */

export enum DeployEnv {
	localhost = 'locahost',
	develop = 'development',
	staging = 'staging',
	production = 'production',
}

enum NodeEnv {
	develop = 'development',
	production = 'production',
}

export const ENV = {
	// App
	NODE_ENV: process.env.NODE_ENV!,
	IS_DEBUG: process.env.NODE_ENV === NodeEnv.develop,
	IS_PROD: process.env.NODE_ENV === NodeEnv.production,
}

export function parseDeployEnv(env = 'localhost'): DeployEnv {
	switch (env) {
		case 'localhost':
			return DeployEnv.localhost
		case 'development':
			return DeployEnv.develop
		case 'staging':
			return DeployEnv.staging
		case 'production':
			return DeployEnv.production
	}

	throw new Error('There is a problem parsing the deployment env.')
}
