// eslint:disable:only-arrow-functions
// Polyfills for IE<11 support
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

if (!String.prototype.startsWith) {
	String.prototype.startsWith = function (search, pos) {
		return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search
	}
}

if (!String.prototype.endsWith) {
	String.prototype.endsWith = function (search, thisLen) {
		if (thisLen === undefined || thisLen > this.length) {
			thisLen = this.length
		}
		return this.substring(thisLen - search.length, thisLen) === search
	}
}
const _o: any = Object
if (!Object.values) {
	_o.values = function (arg: any) {
		return Object.keys(arg).reduce(function (out, k) {
			if (arg.hasOwnProperty(k)) {
				out.push(arg[k])
			}
			return out
		}, [] as any[])
	}
}

if (!Object.entries) {
	_o.entries = function (arg: any) {
		return Object.keys(arg).reduce(function (out, k) {
			if (arg.hasOwnProperty(k)) {
				out.push([k, arg[k]])
			}
			return out
		}, [] as any[])
	}
}
