import { CurrencySigns, LocaleToCurrency, Money, ShortDistanceUnits, User } from 'src/travelsuit'
import { Currency } from 'src/types/common'

import { TFunction } from './i18n/i18n'
import { getCurrentLocale } from './locale-utils'

interface NumberTransformConfig {
	precision: number
	removeZeroes: boolean
	locale: string
}

interface CurrencyConfig {
	currency: Currency
}

type GetCurrencyFormatterConfig = Partial<NumberTransformConfig & CurrencyConfig>
type GetNumberFormatterConfig = Partial<
	NumberTransformConfig & Omit<Intl.NumberFormatOptions, 'maximumFractionPoints' | 'minimumFractionPoints'>
>

export function getNumberFormatter({ precision = 2, locale, ...formatterConfig }: GetNumberFormatterConfig = {}) {
	return new Intl.NumberFormat(locale ?? getCurrentLocale(), {
		...formatterConfig,
		maximumFractionDigits: precision,
		minimumFractionDigits: precision,
	})
}

function getCurrencyFormatter({
	precision,
	currency: curr = Currency.USD,
}: GetCurrencyFormatterConfig = {}): Intl.NumberFormat {
	return getNumberFormatter({
		precision,
		style: 'currency',
		currency: curr.toString(),
	})
}

function removeZerosFromText(text: string, precision?: number) {
	const decSep =
		getNumberFormatter({ precision })
			.formatToParts(1.1)
			.find((part) => part.type === 'decimal')?.value ?? '.'
	return text.replace(new RegExp(`\\${decSep}0+$`), '')
}

export function getUserCurrency(): Currency {
	const user: User = JSON.parse(window.localStorage.getItem('internal_user') ?? '{}')
	return user.currency_code ?? LocaleToCurrency[getCurrentLocale()]
}

export function commatize(
	num: string | number,
	{ precision = 2, removeZeroes = true, ...rest }: Partial<NumberTransformConfig> = {},
): string {
	let formatted = getNumberFormatter({ precision, ...rest }).format(Number(num))
	if (removeZeroes) {
		formatted = removeZerosFromText(formatted)
	}
	return formatted
}

export function getCurrencySign(curr: Currency = getUserCurrency(), showCurrencyName?: boolean) {
	return showCurrencyName ? curr.toString() + ' (' + CurrencySigns[curr] + ')' : CurrencySigns[curr]
}

export function currency(
	num: number | string,
	{ removeZeroes, currency: curr = getUserCurrency(), ...rest }: Partial<NumberTransformConfig & CurrencyConfig> = {},
) {
	const actualNumber: number = typeof num === 'number' ? num : Number(num)
	let formattedNumber = getCurrencyFormatter({ currency: curr, ...rest }).format(actualNumber)
	if (removeZeroes) {
		formattedNumber = removeZerosFromText(formattedNumber)
	}

	return formattedNumber
}

interface DistanceResult {
	distance: string
	amount: number
	key: string
}

function distance(
	dist: number,
	keySuffix: 'short' | 'long' = 'short',
	unit: ShortDistanceUnits = ShortDistanceUnits.KM,
): DistanceResult {
	const isMI = [ShortDistanceUnits.MI].includes(unit)
	const isK = dist > 1

	return {
		distance: commatize(isMI || isK ? dist : dist * 1000, { removeZeroes: true }),
		amount: isMI || isK ? dist : dist * 1000,
		key: `units.${isMI ? 'miles' : isK ? 'kilometers' : 'meters'}-${keySuffix}`,
	}
}

const getDistanceUnitsTranslations = (t: TFunction, { amount, count }: { amount: string; count: number }) => ({
	'units.kilometers-long': t('units.kilometers-long', '{{amount}} Kilometer', { count, amount }),
	'units.kilometers-short': t('units.kilometers-short', '{{amount}} KM', { count, amount }),
	'units.meters-long': t('units.meters-long', '{{amount}} meter', { count, amount }),
	'units.meters-short': t('units.meters-short', '{{amount}} m', { count, amount }),
	'units.miles-long': t('units.miles-long', '{{amount}} Mile', { count, amount }),
	'units.miles-short': t('units.miles-short', '{{amount}} MI', { count, amount }),
})

export function distanceT(
	t: TFunction,
	dist: number,
	keySuffix: 'short' | 'long' = 'short',
	unit: ShortDistanceUnits = ShortDistanceUnits.KM,
) {
	const res = distance(dist, keySuffix, unit)

	return getDistanceUnitsTranslations(t, { amount: res.distance, count: res.amount })[res.key]
}

function isMoney(obj: React.ReactNode | Money): obj is Money {
	return !!obj && obj.hasOwnProperty('amount') && obj.hasOwnProperty('currency')
}

export function moneyAsString(obj: React.ReactNode | Money): React.ReactNode {
	if (isMoney(obj)) {
		return currency(obj.amount, { currency: obj.currency })
	}

	if (typeof obj === 'number') {
		return currency(obj)
	}

	return obj
}
