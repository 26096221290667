import { ExpensePolicyTypes } from 'src/redux/actions/actions.types'
import { Callback } from 'src/travelsuit'
import { ExpensePolicy } from 'src/travelsuit/expenses'

export const Actions = {
	getPolicy(callback?: Callback<void, ExpensePolicy>) {
		return {
			type: ExpensePolicyTypes.GetPolicy.REQUEST,
			callback,
		}
	},
	updatePolicy(expensePolicy: ExpensePolicy, callback?: Callback<void, ExpensePolicy>) {
		return {
			type: ExpensePolicyTypes.UpdatePolicy.REQUEST,
			payload: {
				categories_by_country: expensePolicy.categories_by_country,
			},
			callback,
		}
	},

	updateApproversPolicy(expensePolicy: ExpensePolicy, callback?: Callback<void, ExpensePolicy>) {
		return {
			type: ExpensePolicyTypes.UpdateApproversPolicy.REQUEST,
			payload: {
				expense_approver_id: expensePolicy.expense_approver?.id,
				fallback_approver_id: expensePolicy.fallback_approver?.id,
			},
			callback,
		}
	},
}
