import { z } from 'zod'

import { CountriesCode } from 'src/i18nConfig'
import { CountryName, POSCountryName } from 'src/travelsuit'

const TaxAndVatRateZ = z.object({
	id: z.number(),
	country: z.object({ name: POSCountryName, code: z.nativeEnum(CountriesCode) }),
	merchant_country: z.object({ name: CountryName, code: z.union([z.nativeEnum(CountriesCode), z.literal('')]) }),
	all_countries: z.boolean(),
	rate: z.number().min(0).max(100),
	name: z.string().max(255),
})

export type TaxAndVatRate = z.infer<typeof TaxAndVatRateZ>

export const TaxAndVatRateApiZ = TaxAndVatRateZ.pick({
	id: true,
	all_countries: true,
	rate: true,
	name: true,
}).extend({
	country: POSCountryName,
	country_code: z.nativeEnum(CountriesCode),
	merchant_country_name: CountryName,
	merchant_country_code: z.nativeEnum(CountriesCode).optional(),
})

export type TaxAndVatRateApi = z.infer<typeof TaxAndVatRateApiZ>
