import { TFunction } from 'i18next'
import { isNumber, uniq } from 'lodash'

import { getCabinClassName, getCabinClassNameTranslations, isNotNil } from 'src/lib/utils'
import { CabinClassName, getCabinClassLabel, MinimalSegment, Segment } from 'src/travelsuit'
import { FlightUniqueKey, FlightUniqueKeyZ } from 'src/types/flights'

import { Amenities, BookingOption, FlightGroup, FlightOption, FlightOptionSegments } from './types'

export function getBookingOptionByResId(flightGroup: FlightGroup, bookingOptionResId?: string): BookingOption {
	return bookingOptionResId
		? flightGroup.bookingOptions.find((bo) => bo.res_id === bookingOptionResId)!
		: flightGroup.bookingOptions[0]
}

export function getFlightOptionByStep(bookingOption: BookingOption, stepIndex: number): FlightOption {
	return bookingOption.flights[stepIndex]
}

export function getFareComponents<FC extends { segment_ids: string[] }>(
	bookingOption: { fare_components: FC[] },
	flightOption: { segments: { segment_id_ref?: string }[] },
) {
	return bookingOption.fare_components.filter((fc) =>
		fc.segment_ids.some((sid) => flightOption.segments.some((s) => s.segment_id_ref === sid)),
	)
}

export function getFlightAmenities(flightOption: FlightOptionSegments): Amenities {
	return Object.fromEntries(
		flightOption.segments
			.flatMap(
				(segment) =>
					segment.amenities?.map((amenity) => {
						return amenity.exists && amenity.amenity ? amenity.amenity : undefined
					}) ?? [],
			)
			.filter(isNotNil)
			.map((a) => [a, true]),
	)
}

export function getFlightSliceEmissions(flightSlice: FlightOptionSegments): number | undefined {
	return flightSlice.segments.reduce(
		(acc, seg) => (seg.emissions && isNumber(acc) ? acc + seg.emissions : undefined),
		0 as number | undefined,
	)
}

export function getFlightSliceFareFamily(
	fareComponents?: { price_class_name: string | null }[],
	flightOption?: { segments: { cabin?: CabinClassName | null; cabin_commercial_name?: string | null }[] },
): string {
	const fareComponentsPriceClass = (fareComponents ?? []).map((fc) => fc.price_class_name || '').join(',')
	const segmentsPriceClass = (flightOption?.segments ?? []).map((s) => s.cabin_commercial_name).join(',')
	const cabinType = (flightOption?.segments ?? []).map((s) => s.cabin || '').join(',')

	return `${fareComponentsPriceClass || segmentsPriceClass}:${cabinType}`.toLowerCase()
}

export function getFlightSliceFareTitle(
	t: TFunction,
	fareComponents?: { price_class_name: string | null }[],
	flightOption?: { segments: { cabin?: CabinClassName | null; cabin_commercial_name?: string | null }[] },
): string {
	const fareComponentsPriceClass = uniq(fareComponents?.map((fc) => fc.price_class_name))
		.filter(isNotNil)
		.join(', ')
	const segmentsPriceClass = uniq(flightOption?.segments.map((s) => s.cabin_commercial_name))
		.filter(isNotNil)
		.join(', ')
	const fareName = fareComponentsPriceClass || segmentsPriceClass
	const cabinName = uniq(
		(flightOption?.segments ?? []).map((s) => s.cabin && getCabinClassLabel({ cabinClassName: s.cabin, t })),
	)
		.filter(isNotNil)
		.join(', ')

	return `${fareName}${fareName && ' '}(${cabinName})`
}

export function getFlightSegmentFareTitle(t: TFunction, segment: MinimalSegment | Segment): number | undefined {
	return segment.cabin_commercial_name ?? getCabinClassNameTranslations(t)[getCabinClassName(segment.cabin)]
}

export function getFlightProductEmissions(flightProduct: { flights: FlightOptionSegments[] }): number | undefined {
	return flightProduct.flights.reduce(
		(acc, fl) => {
			const sliceEmissions = getFlightSliceEmissions(fl)
			return sliceEmissions && isNumber(acc) ? acc + sliceEmissions : undefined
		},
		0 as number | undefined,
	)
}

export function getFlightSliceFareBasis(fareComponents?: { fare_basis_code: string | null }[]): string {
	return fareComponents?.map((fc) => fc.fare_basis_code).join(',') ?? ''
}

/**
 * Creates a key identifying the specific airplane journey without attachments to the fare and its rules.
 * We can't use `flight_identifier` or `flight.id` created by the API because they include fare information in the hash.
 * `flight_option_key` is too cardinal as it includes the fare and the rules.
 */
export function getFlightUniqueKey(flightSlice: {
	segments: {
		carrier: { code: string; flight_number: string }
		departure: { datetime: string; airport: { code: string } }
	}[]
}): FlightUniqueKey {
	return FlightUniqueKeyZ.parse(
		flightSlice.segments
			.map(
				({ carrier, departure }) =>
					`${carrier.code},${carrier.flight_number},${departure.datetime},${departure.airport.code}`,
			)
			.join('-'),
	)
}
