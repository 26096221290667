import { replaceEntityById } from 'src/lib/entity/replaceEntityById'
import { ExpenseReportsTypes, IAction } from 'src/redux/actions'
import {
	Expense,
	ExpenseInReport,
	ExpenseInReportApi,
	ExpenseReport,
	ExpenseReportApi,
	ExpenseReportApiWithExpenseNumbers,
} from 'src/travelsuit/expenses'

import { normalize as normalizeExpense } from './expenses-new.reducer'

export type ExpenseReportsState = Array<ExpenseReport>

function expenseReportsReducer(state: ExpenseReportsState = [], action: IAction) {
	switch (action.type) {
		case ExpenseReportsTypes.GetReports.SUCCESS:
			return action.payload.map(normalize)
		case ExpenseReportsTypes.GetReport.SUCCESS:
		case ExpenseReportsTypes.SubmitReport.SUCCESS:
		case ExpenseReportsTypes.ApproveReport.SUCCESS:
		case ExpenseReportsTypes.RejectReport.SUCCESS:
			return replaceEntityById(state, normalize(action.payload))
		default:
			return state
	}
}

export default expenseReportsReducer

export function normalize(report: ExpenseReportApi): ExpenseReport {
	const normalized = [
		'id',
		'name',
		'status',
		'start_date',
		'end_date',
		'trip_name',
		'description',
		'submitter',
		'approver',
	].reduce(
		(acc, key) =>
			report[key]
				? {
						...acc,
						[key]: report[key],
					}
				: acc,
		{},
	) as ExpenseReport

	return {
		...normalized,
		expenses: report.expenses.map((expense) => normalizeExpenseInReport(expense)),
		reasons: report.reasons?.map((reason) => ({
			reason: reason.reason,
			rejecter: reason.rejecter,
			id: reason.id,
			created_at: new Date(reason.created_dt),
		})),
		created_at: new Date(report.created_dt),
		modified_at: report.last_modified_dt ? new Date(report.last_modified_dt) : null,
		modified_status_at: report.last_status_modified_dt ? new Date(report.last_status_modified_dt) : null,
	}
}

export function denormalize(expenseReport: ExpenseReport): ExpenseReportApiWithExpenseNumbers {
	const denormalized = ['id', 'name', 'status', 'trip_name', 'description'].reduce(
		(acc, key) =>
			expenseReport[key]
				? {
						...acc,
						[key]: expenseReport[key],
					}
				: acc,
		{},
	) as ExpenseReportApi

	return {
		...denormalized,
		expenses: expenseReport.expenses.map((expense) => expense.id),
		submitter_id: expenseReport.submitter.id,
		start_date: expenseReport.start_date
			? new Date(expenseReport.start_date.toString().slice(0, 28)).toISOString().slice(0, 10)
			: undefined,
		end_date: expenseReport.end_date
			? new Date(expenseReport.end_date.toString().slice(0, 28)).toISOString().slice(0, 10)
			: undefined,
		created_dt: expenseReport.created_at.toISOString(),
		last_modified_dt: expenseReport.modified_at ? expenseReport.modified_at.toISOString() : null,
	}
}

function normalizeExpenseInReport(expense: ExpenseInReportApi): ExpenseInReport {
	return {
		...(normalizeExpense(expense) as Expense),
		report_amount: expense.report_amount,
	}
}
