import moment from 'moment'

import { entityGenerator, TimeFormatsLocaleUnsafe } from 'src/lib/utils'
import { FlightSearchDetails, User } from 'src/travelsuit'
import { parseRouteDateTime } from 'src/travelsuit/flights'

export interface TrainDiscountCard {
	id: string
	vendor_code: string
	name_en: string
	vendor?: string
	active?: boolean
}

export enum TrainDiscountCardType {
	BahnCard = 'BAHN_CARD',
	BahnBonusBusiness = 'BAHN_BONUS_BUSINESS',
}

export const NO_BAHN_CARD_VENDOR_CODE = '0'

export interface UserRailCard {
	id?: string
	card_type_id: string | null
	card_number?: string
	expiration_date?: Date
	card_type?: TrainDiscountCard | null
	card_type_name?: TrainDiscountCardType | null
	is_preferred: boolean
}

export const emptyUserRailCard = entityGenerator<UserRailCard>({
	card_number: '',
	card_type_id: null,
	card_type_name: null,
	is_preferred: false,
})

type TrainClass = 'first' | 'second'
type TrainLanguage = 'en' | 'de'

export interface DataForTrainRedirect {
	departure?: number | null
	arrival?: number | null
	departure_date: string | null
	return_date?: string
	train_class: TrainClass
	lang: TrainLanguage
	discount_type: string
	traveler_id: number
	trip_id?: number | null
}

export const getRedirectDataFromSearchDetails = (
	searchDetails: FlightSearchDetails,
	travelers: User[],
	bahnCardVendorCode: string,
	trainLanguage?: TrainLanguage,
): DataForTrainRedirect => {
	const route = searchDetails.routes[0]
	const backRoute = searchDetails.routes[1]
	const [trainClass]: TrainClass[] = searchDetails.cabin_class.split('_', 1) as TrainClass[]
	const [travelerId] = travelers.map((t) => t.id!)

	return {
		departure: route.from_location?.number,
		arrival: route.to_location?.number,
		departure_date: moment(parseRouteDateTime(route)).format(),
		return_date: backRoute ? moment(parseRouteDateTime(backRoute)).format() : undefined,
		train_class: trainClass,
		lang: trainLanguage ?? 'en',
		traveler_id: travelerId,
		discount_type: bahnCardVendorCode,
		trip_id: searchDetails.trip_id ?? null,
	}
}

export function prepareUserRailCardJson(card: UserRailCard) {
	const { id, ...rest } = { ...card }
	return {
		...rest,
		expiration_date: card.expiration_date
			? moment(card.expiration_date).format(TimeFormatsLocaleUnsafe.DateOnly)
			: undefined,
	}
}

export interface TrainDiscountProvider {
	code: string
	name: string
}

export interface TrainDiscountProgram {
	id: number
	provider: TrainDiscountProvider
	program_number: string
}
