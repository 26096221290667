import { ImmutableMap } from 'src/lib/immutable/ImmutableMap'
import { mapGetOrSet } from 'src/lib/map'
import {
	getFareComponents,
	getFlightProductEmissions,
	getFlightSliceEmissions,
	getFlightSliceFareFamily,
	getFlightUniqueKey,
} from 'src/organisms/FlightSearchNew/flightUtils'
import { BookingOption, FlightGroupUnfiltered } from 'src/organisms/FlightSearchNew/types'
import { SearchResult } from 'src/travelsuit'
import { FlightUniqueKey } from 'src/types/flights'

import { IAction } from '../actions/action-helpers'
import { SearchResultsTypes } from '../actions/actions.types'

function groupBookingOptionsByFlight(searchResult: SearchResult) {
	return (searchResult?.flight_results?.results ?? []).reduce((flightGroupMap, bookingOption: BookingOption) => {
		// Flights in the bookingOption are flight_options: 1 for a one-way, 2 for a roundtrip, or 3+ for a multi city.
		for (const flightOption of bookingOption.flights) {
			const flightKey = getFlightUniqueKey(flightOption)

			const flightGroup = mapGetOrSet(flightGroupMap, flightKey, (key) => ({
				bookingOptions: [],
				exampleFlightOption: flightOption,
				flightUniqueKey: key,
				emissionsByBOResId: new Map(),
				emissionsByFlightOptionKey: new Map(),
				fareComponentsByFlightOptionKey: new Map(),
				fareFamilyByFlightOptionKey: new Map(),
			}))

			flightGroup.bookingOptions.push(bookingOption)

			const fareComponents = getFareComponents(bookingOption, flightOption)

			flightGroup.emissionsByBOResId.set(bookingOption.res_id, getFlightProductEmissions(bookingOption))
			flightGroup.emissionsByFlightOptionKey.set(flightOption.flight_option_key, getFlightSliceEmissions(flightOption))
			flightGroup.fareComponentsByFlightOptionKey.set(flightOption.flight_option_key, fareComponents)
			flightGroup.fareFamilyByFlightOptionKey.set(
				flightOption.flight_option_key,
				getFlightSliceFareFamily(fareComponents, flightOption),
			)
		}

		return flightGroupMap
	}, new Map<FlightUniqueKey, FlightGroupUnfiltered>())
}

export type SearchResultsNewState = ImmutableMap<number, ReturnType<typeof groupBookingOptionsByFlight>>

function searchResultsNewReducer(
	state: SearchResultsNewState = new ImmutableMap(),
	action: IAction,
): SearchResultsNewState {
	switch (action.type) {
		case SearchResultsTypes.UnsetSearchResult:
			return state.delete(action.payload.searchId)
		case SearchResultsTypes.SetSearchResult:
			return state.set(action.payload.id, groupBookingOptionsByFlight(action.payload))
	}
	return state
}

export default searchResultsNewReducer
