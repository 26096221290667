import { Severity, withScope } from '@sentry/react'
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import { z } from 'zod'

import { captureAndLogError } from 'src/lib/error'
import { AbsoluteURL } from 'src/types'

export type EnforceRelativeURL<URL extends string> = URL extends `/${string}`
	? never
	: URL extends `http${string}`
		? never
		: URL

export type BaseRequestConfig<
	ReqZ extends z.ZodTypeAny = z.ZodType<void>,
	ResZ extends z.ZodTypeAny = z.ZodType<void>,
> = Omit<AxiosRequestConfig, 'baseURL' | 'data' | 'method' | 'transformRequest' | 'transformResponse' | 'url'> & {
	data?: z.infer<ReqZ>
	requestSchema?: ReqZ
	responseSchema?: ResZ
}

export type RequestOverrides = Omit<
	AxiosRequestConfig,
	'auth' | 'baseURL' | 'data' | 'method' | 'transformRequest' | 'transformResponse' | 'url' | 'withCredentials'
>

const NoSchemaZ = z.unknown()

export class BaseApiClient {
	client: AxiosInstance

	constructor(baseURL: AbsoluteURL, opts?: Omit<AxiosRequestConfig, 'baseURL'>) {
		this.client = axios.create({
			baseURL,
			...opts,
			headers: {
				'Content-type': 'application/json',
				...opts?.headers,
			},
		})
	}

	async request<
		URL extends string,
		ReqZ extends z.ZodTypeAny = z.ZodType<void>,
		ResZ extends z.ZodTypeAny = z.ZodType<void>,
	>(
		method: Method,
		url: EnforceRelativeURL<URL>,
		config?: BaseRequestConfig<ReqZ, ResZ>,
	): Promise<AxiosResponse<z.infer<ResZ>>> {
		const { data, requestSchema, responseSchema, ...rest } = config ?? {}
		const fullUrl = this.client.getUri({ url })

		const reqValidation = (config?.requestSchema ?? NoSchemaZ).safeParse(data)

		if (!reqValidation.success) {
			withScope((scope) => {
				scope.setFingerprint([method, fullUrl, 'validation'])
				captureAndLogError(reqValidation.error, {
					level: Severity.Warning,
					extra: {
						method,
						url: fullUrl,
						source: 'request_validation',
					},
				})
			})
		}

		const result = await this.client.request({
			method,
			url,
			data,
			...rest,
		})

		const resValidation = (config?.responseSchema ?? NoSchemaZ).safeParse(result.data)

		if (!resValidation.success) {
			withScope((scope) => {
				scope.setFingerprint([method, fullUrl, 'validation'])
				captureAndLogError(resValidation.error, {
					level: Severity.Warning,
					extra: {
						method,
						url: fullUrl,
						source: 'response_validation',
					},
				})
			})
		}

		return result
	}
}
