import React, { useState } from 'react'

import { countryFlag } from 'src/assets/countryFlags'
import { SizedBox } from 'src/atoms/GenericComponents/GenericComponents'
import { Flag, LanguageSelector, useLanguageSelectorTranslation } from 'src/atoms/LanguageSelector'
import { useTranslation } from 'src/lib/i18n/i18n'
import { getRegionFromLocale } from 'src/lib/locale-utils'

import { Container, LanguageSwitcherButton, Menu, Title } from './LanguageSwitcher.components'
import { useLanguageSwitcher } from './LanguageSwitcher.hooks'

export const LanguageSwitcherNormal: React.FC = () => {
	const { countryLanguages, locale, onChange } = useLanguageSwitcher()
	const [open, setOpen] = useState(false)
	const { t } = useTranslation()
	const translation = useLanguageSelectorTranslation()

	const buttonRef = React.createRef<HTMLButtonElement>()
	const menuRef = React.createRef<HTMLDivElement>()

	const country = getRegionFromLocale(locale)

	return (
		<Container>
			<LanguageSwitcherButton ref={buttonRef} onClick={() => setOpen((open) => !open)}>
				{country && <Flag src={countryFlag.svgUrl[country]} />}
				<span>
					{translation.title[locale]} {translation.label[locale]}
				</span>
			</LanguageSwitcherButton>
			{open && (
				<Menu
					attachTo={{ top: true, right: true }}
					attachOffset={{ y: 50, x: -90 }}
					onClickOut={(e: React.MouseEvent<any, MouseEvent>) => {
						const isClickonMenuIcon = (node: any) => buttonRef.current?.contains(node)

						if (!isClickonMenuIcon(e.target)) {
							setOpen(false)
						}
					}}
				>
					<SizedBox ref={menuRef} height={menuRef.current?.offsetHeight}>
						<Title>{t('locale-selector.select-locale', 'Select language')}</Title>
						<LanguageSelector
							availableLanguages={countryLanguages}
							language={locale}
							onChange={(language) => {
								onChange(language)
								setOpen(false)
							}}
						/>
					</SizedBox>
				</Menu>
			)}
		</Container>
	)
}
