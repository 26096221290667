import { TFunction } from 'src/lib/i18n/i18n'
import { distanceT } from 'src/lib/number-utils'
import { makeAbsoluteUrl } from 'src/lib/staticFiles'
import { capitalize } from 'src/lib/utils'
import { getStoreState } from 'src/redux/stores'
import {
	CarResult,
	ItineraryCarBooking,
	ShortDistanceUnits,
	VendorInfo,
	WithCarVendorInfo,
	WithName,
} from 'src/travelsuit'
import {
	CarAdditionalDetailsName,
	CarAgencyLocation,
	CarBodyType,
	CarCategory,
	CarFuel,
	CarGroup,
	CarRate,
	CarTransmission,
	CarType,
	CarVendor,
} from 'src/travelsuit/cars.type'
import { Currency } from 'src/types/common'

import { emptyCountry } from './locations'

export function getCarCategoryLabel({ carCategory, t }: { carCategory: CarCategory; t: TFunction }) {
	return {
		[CarCategory.All]: t('car-category-labels.all'),
		[CarCategory.Mini]: t('car-category-labels.mini'),
		[CarCategory.MiniElite]: t('car-category-labels.mini-elite'),
		[CarCategory.Economy]: t('car-category-labels.economy'),
		[CarCategory.EconomyElite]: t('car-category-labels.economy-elite'),
		[CarCategory.Compact]: t('car-category-labels.compact'),
		[CarCategory.CompactElite]: t('car-category-labels.compact-elite'),
		[CarCategory.IntermediateS]: t('car-category-labels.intermediate-s'),
		[CarCategory.IntermediateElite]: t('car-category-labels.intermediate-elite'),
		[CarCategory.Standard]: t('car-category-labels.standard'),
		[CarCategory.StandardElite]: t('car-category-labels.standard-elite'),
		[CarCategory.Fullsize]: t('car-category-labels.fullsize'),
		[CarCategory.FullsizeElite]: t('car-category-labels.fullsize-elite'),
		[CarCategory.Premium]: t('car-category-labels.premium'),
		[CarCategory.PremiumElite]: t('car-category-labels.premium-elite'),
		[CarCategory.Luxury]: t('car-category-labels.luxury'),
		[CarCategory.LuxuryElite]: t('car-category-labels.luxury-elite'),
		[CarCategory.Oversize]: t('car-category-labels.oversize'),
		[CarCategory.Special]: t('car-category-labels.special'),
	}[carCategory]
}

export const getCarFuelTypeLabelsTranslations = (t: TFunction): Record<CarFuel, string> => ({
	[CarFuel.Unspecified]: t('car-fuel-type-labels.unspecified', 'Unspecified'),
	[CarFuel.Diesel]: t('car-fuel-type-labels.diesel', 'Diesel'),
	[CarFuel.Electric]: t('car-fuel-type-labels.electric', 'Electric'),
	[CarFuel.Hybrid]: t('car-fuel-type-labels.hybrid', 'Hybrid'),
	[CarFuel.LPG]: t('car-fuel-type-labels.lpg', 'LPG'),
	[CarFuel.Hydrogen]: t('car-fuel-type-labels.hydrogen', 'Hydrogen'),
	[CarFuel.Multi]: t('car-fuel-type-labels.multi', 'Multi'),
	[CarFuel.Petrol]: t('car-fuel-type-labels.petrol', 'Petrol'),
	[CarFuel.Ethanol]: t('car-fuel-type-labels.ethanol', 'Ethanol'),
})

export const getCarCategoryGroupLabelsTranslations = (t: TFunction): Record<CarGroup, string> => ({
	[CarGroup.Small]: t('car-group-labels.small', 'Small'),
	[CarGroup.Medium]: t('car-group-labels.medium', 'Medium'),
	[CarGroup.Large]: t('car-group-labels.large', 'Large'),
	[CarGroup.Premium]: t('car-group-labels.premium', 'Premium'),
	[CarGroup.SUV]: t('car-group-labels.suv', 'SUV'),
	[CarGroup.Van]: t('car-group-labels.van', 'Van'),
})

export const getCarTransmissionLabelsTranslations = (t: TFunction): Record<CarTransmission, string> => ({
	[CarTransmission.Manual]: t('car-transmission-labels.manual', 'Manual'),
	[CarTransmission.Automatic]: t('car-transmission-labels.automatic', 'Automatic'),
})

export function getCarDestriptionTitles({
	carDetailsName,
	t,
}: {
	carDetailsName: CarAdditionalDetailsName
	t: TFunction
}) {
	return {
		additional_service: t('car-description-titles.additional-service'),
		age: t('car-description-titles.age'),
		driver: t('car-description-titles.driver'),
		drop_off: t('car-description-titles.drop_off'),
		fees: t('car-description-titles.fees'),
		fuel: t('car-description-titles.fuel'),
		geographic_restriction: t('car-description-titles.geographic-restrictions'),
		insurance: t('car-description-titles.insurance'),
		license: t('car-description-titles.license'),
		surcharges: t('car-description-titles.surcharges'),
		taxes: t('car-description-titles.taxes'),
		waivers: t('car-description-titles.waivers'),
	}[carDetailsName]
}

export function carThumbnail(car: Pick<CarResult, 'thumbnail'>): string {
	return car.thumbnail
}

function parseMileageValue(mileage: CarResult['mileage']): { amount: number; unit: ShortDistanceUnits } {
	if (mileage === 'UNL') {
		return {
			amount: 0,
			unit: ShortDistanceUnits.KM,
		}
	}

	try {
		const [_amt, _unit] = mileage.split(' ')

		return {
			amount: Number(_amt),
			unit: _unit as ShortDistanceUnits,
		}
	} catch (e) {
		console.error(e)
		return {
			amount: 0,
			unit: ShortDistanceUnits.KM,
		}
	}
}

export function carMileageT(t: TFunction, car: CarResult, keySuffix?: 'short' | 'long'): string {
	const mileageValue = parseMileageValue(car.mileage)

	return !isNaN(mileageValue.amount) && mileageValue.amount
		? t('car-mileage.total', 'Total {{amount}}', {
				amount: distanceT(t, mileageValue.amount, keySuffix, mileageValue.unit),
			})
		: keySuffix === 'short'
			? t('car-mileage.unlimited', 'Unlimited')
			: t('car-mileage.unlimited-long', 'Unlimited Mileage')
}

export function carLocationDescription(location: CarAgencyLocation): string {
	if (!location) {
		return ''
	}

	if (location.name) {
		return location.name.replace(/([,?!.])\s*/g, '$1 ')
	}
	return carLocationAddress(location)
}

export function carLocationAddress(location: CarAgencyLocation): string {
	const { address, city, state } = location
	const country =
		getStoreState().countries.byISO.get(location.country) ??
		emptyCountry({ name: location.country, iso: location.country })
	return [
		capitalize(address, { forceCaseOnAllChars: true }),
		capitalize(city, { forceCaseOnAllChars: true }),
		state,
		country.name,
	]
		.filter(Boolean)
		.join(', ')
}

export function hasReturnLocation(car: Pick<CarResult, 'return_location' | 'pickup_location'>) {
	return car.return_location && car.return_location.iata_or_code !== car.pickup_location.iata_or_code
}

export function hasSpecificLocationAddress(location: CarAgencyLocation): boolean {
	return carLocationDescription(location) !== carLocationAddress(location)
}

export function carLocations(car: Pick<CarResult, 'return_location' | 'pickup_location'>) {
	if (!hasReturnLocation(car)) {
		return [car.pickup_location]
	}

	return [car.pickup_location, car.return_location]
}

const CarCategoryMapping: Record<CarCategory, CarGroup | null> = {
	[CarCategory.All]: null,
	[CarCategory.Compact]: CarGroup.Small,
	[CarCategory.CompactElite]: CarGroup.Small,
	[CarCategory.Mini]: CarGroup.Small,
	[CarCategory.MiniElite]: CarGroup.Small,
	[CarCategory.Economy]: CarGroup.Small,
	[CarCategory.EconomyElite]: CarGroup.Small,
	[CarCategory.IntermediateS]: CarGroup.Medium,
	[CarCategory.IntermediateElite]: CarGroup.Medium,
	[CarCategory.Standard]: CarGroup.Medium,
	[CarCategory.StandardElite]: CarGroup.Medium,
	[CarCategory.Fullsize]: CarGroup.Large,
	[CarCategory.FullsizeElite]: CarGroup.Large,
	[CarCategory.Oversize]: CarGroup.Large,
	[CarCategory.Premium]: CarGroup.Premium,
	[CarCategory.PremiumElite]: CarGroup.Premium,
	[CarCategory.Luxury]: CarGroup.Premium,
	[CarCategory.LuxuryElite]: CarGroup.Premium,
	[CarCategory.Special]: CarGroup.Premium,
}

const CarBodyTypeMapping: Record<CarBodyType, CarGroup | null> = {
	[CarBodyType.All]: null,
	[CarBodyType.TwoOrThreeDoors]: null,
	[CarBodyType.TwoOrFourDoors]: null,
	[CarBodyType.FourOrFiveDoors]: null,
	[CarBodyType.Wagon]: CarGroup.Large,
	[CarBodyType.Van]: CarGroup.Van,
	[CarBodyType.Limousine]: CarGroup.Premium,
	[CarBodyType.Sport]: CarGroup.Premium,
	[CarBodyType.Convertible]: CarGroup.Premium,
	[CarBodyType.SUV]: CarGroup.SUV,
	[CarBodyType.OpenAirAllTerrain]: null,
	[CarBodyType.Special]: CarGroup.Premium,
	[CarBodyType.PickupRegularCab]: null,
	[CarBodyType.PickupExtendedCab]: null,
	[CarBodyType.SpecialOfferCar]: null,
	[CarBodyType.Coupe]: null,
	[CarBodyType.Monospace]: null,
	[CarBodyType.MotorHome]: null,
	[CarBodyType.TwoWheelVehicle]: null,
	[CarBodyType.Roadster]: CarGroup.Premium,
}

export function getCarGroup(type: CarType): CarGroup {
	const cat: CarGroup | null = CarCategoryMapping[type.category]
	const body: CarGroup | null = CarBodyTypeMapping[type.body_type]

	return body ?? cat!
}

export const CarVendors: CarVendor[] = ['SABRE', 'OFRAN']

const CarRentalLogos = ['alamo', 'avis', 'budget', 'dollar', 'enterprise', 'europcar', 'hertz', 'sixt', 'thrifty']

export function getAgencyLogo(agency: WithName): string | null {
	if (!agency) {
		return null
	}
	const cleanName = getAgencyCleanName(agency)
	return CarRentalLogos.includes(cleanName) ? makeAbsoluteUrl(`static/car-rental-logos/${cleanName}.png`) : null
}

export function getAgencyCleanName(agency: WithName): string {
	const [cleanName] = agency.name.toLowerCase().split(' ')
	return cleanName
}

export function prepareCarJson(
	{ ...car }: CarResult,
	{ ...rate }: CarRate,
	additional_equip?: string[],
	searchId?: number,
) {
	return {
		car_search_result: car,
		car_rate_result: rate,
		additional_equip: additional_equip,
		search_id: searchId,
		__original_total_price: undefined,
	}
}

export function carBookingToCarResult(booking: ItineraryCarBooking): CarResult {
	return {
		...booking,
		...booking.car,
		pickup_datetime: '',
		return_datetime: '',
		rent_agency: booking.car.car_rent_vendor,
		num_of_seats: 0,
		mileage: 'UNL',
		vendor: 'OFRAN',
		result_index: 0,
		search: {} as any,
		additional_details: {
			additional_service: null,
			age: null,
			driver: null,
			drop_off: null,
			fees: null,
			fuel: null,
			geographic_restriction: null,
			insurance: null,
			license: null,
			special_equip: [],
			surcharges: null,
			taxes: null,
			waivers: null,
			address: null,
			agency_name: null,
			models: null,
		},
		commission: null,
		cancellation_details: {
			amount: 0,
			due_date: null,
			policy: null,
		},
		currency: Currency.USD,
		prepaid: false,
	}
}

export function carLocationHasCoords(location?: CarAgencyLocation | null): boolean {
	return Boolean(location?.latitude && location.longitude)
}

function getVendorPropertyName(vendor: string): string {
	const propertyName = vendor.toLowerCase().split(' ').join('_')
	return propertyName
}

export function getVendorSpecificInfo(car: WithCarVendorInfo): VendorInfo {
	return car.vendor_specific_info?.[getVendorPropertyName(car.vendor)] ?? {}
}
