import { BoxProps as MuiBoxProps } from '@material-ui/core/Box'
import * as system from '@material-ui/system'
import React, { Children, forwardRef } from 'react'
import styled from 'styled-components'

import { BoxProps } from './Box'
import { AdaptiveValue, direction, gap, spacing } from './styledUtils'

/**
 * We are not wrapping a Box to squeeze some additional performance
 */
const StackBox = styled.div<Omit<MuiBoxProps, 'component'> & { ref?: React.Ref<HTMLDivElement> }>`
	${system.borders}
	${system.display}
	${system.flexbox}
	${system.positions}
	${system.palette}
	${system.shadows}
	${system.sizing}
	${system.spacing}
	${system.typography}
	display: flex;
	flex-direction: column;
	${direction}
	${gap}
 	${spacing}
`

export type StackProps = Omit<BoxProps, 'direction' | 'gap' | 'spacing' | 'divider'> & {
	// The current MUI version has no types on the system properties
	direction?: AdaptiveValue<NonNullable<React.CSSProperties['flexDirection']>>
	gap?: AdaptiveValue<NonNullable<React.CSSProperties['gap']>>
	spacing?: AdaptiveValue<NonNullable<React.CSSProperties['gap']>>
	divider?: React.ReactNode
}

/**
 * Remove after upgrading to MUI v5.
 */
export const Stack = forwardRef<HTMLDivElement, StackProps>(function Stack({ children, divider, ...rest }, ref) {
	return (
		<StackBox ref={ref} {...rest}>
			{divider
				? Children.map(children, (child, index) =>
						child ? (
							<React.Fragment>
								{index > 0 && divider}
								{child}
							</React.Fragment>
						) : null,
					)
				: children}
		</StackBox>
	)
})
