import styled, { CSSProperties } from 'styled-components'

import { padUnit } from './units'

const StyleMap = {
	fadeIn: {
		from: 'opacity: 0;',
		to: 'opacity: 1;',
	},
	fadeOut: {
		from: 'opacity: 1;',
		to: 'opacity: 0;',
	},
	slideInTop: {
		from: 'opacity: 0; transform: translateY(-5%);',
		to: 'opacity: 1; transform: translateY(0);',
	},
	slideInTopMargin: {
		from: 'opacity: 0; margin-top: -20px;',
		to: 'opacity: 1; margin-top: 0;',
	},
	slideInLeft: {
		from: 'opacity: 0; transform: translateX(-5%);',
		to: 'opacity: 1; transform: translateX(0);',
	},
	slideInRight: {
		from: 'opacity: 0; transform: translateX(5%);',
		to: 'opacity: 1; transform: translateX(0);',
	},
	zoomFade: {
		from: 'opacity: 0; transform: scale(0);',
		to: 'opacity: 1; transform: scale(1);',
	},
	slideDown: {
		from: 'opacity: 0; transform: scaleY(0);',
		to: 'opacity: 1; transform: scaleY(1);',
	},
	slideUp: {
		from: 'opacity: 1; transform: scaleY(1);',
		to: 'opacity: 0; transform: scaleY(0);',
	},
	rippleOut2xCentered: {
		from: 'opacity: 1; transform: translate(-50%, -50%) scale(0);',
		to: 'opacity: 0; transform: translate(-50%, -50%) scale(1.5);',
	},
	rotateOnce: {
		from: 'transform: rotate(0deg);',
		to: 'transform: rotate(360deg);',
	},
}

class AnimationName {
	public startingStyle: string
	public endingStyle: string

	constructor(private name: string) {
		const map = StyleMap[this.name]
		const keys = Object.keys(map)
		this.startingStyle = (map.baseStyle || '') + (map.from || map['0%'] || map[keys[0]])
		this.endingStyle = map.to || map['100%'] || map[keys[keys.length - 1]]
	}

	public toString() {
		return this.name
	}

	public animate(ms: number, dir = 'forwards') {
		return `
			${this.startingStyle}
			animation: ${this.name} ${ms}ms ${dir};
		`
	}
}

type AnimationString = AnimationName & string

export type AnimationNames = keyof typeof StyleMap

export const animations: Record<AnimationNames, AnimationString> = Object.keys(StyleMap).reduce(
	(all, k) => ({ ...all, [k]: new AnimationName(k) }),
	{} as Record<AnimationNames, AnimationString>,
)

export let animationDefs = ''
for (const name of Object.keys(StyleMap).filter((k) => k !== 'baseStyle')) {
	animationDefs += `
		@keyframes ${name} {
			${Object.keys(StyleMap[name])
				.map((k) => `${k} { ${StyleMap[name][k]} }`)
				.join('\n')}
		}
	`
}

/** Creates a counter, and returns a function which increments the counter by one and returns the current value after being processed by `mutator`.
 * Pass return value to `animation-delay` CSS property.
 */
export const createAnimatorDelaySequence = (
	mutator: (i: number, v: number) => number = (n) => (n + 1) * 200,
	startingValue = 0,
) => {
	let i = 0
	let v = startingValue
	return (_i?: number, _v?: number) => (v = mutator(_i !== undefined ? _i : i++, _v !== undefined ? _v : v))
}

export interface EnterAnimProps {
	duration?: number
	delay?: number
	animation?: AnimationNames
	easing?: CSSProperties['animationTimingFunction']
	animateChildren?: boolean
}

export const EnterAnim = styled.div<EnterAnimProps>`
	${(props) => (props.animateChildren ? `& > * {` : '')}
	${(props) => animations[props.animation!].startingStyle}
	${(props) =>
		`animation: ${animations[props.animation!]} ${props.duration}ms ${props.delay}ms ${props.easing} forwards;`}
	${(props) => (props.animateChildren ? `}` : '')}
`

EnterAnim.defaultProps = {
	duration: 500,
	delay: 0,
	animation: 'slideInTop',
	easing: 'ease-in-out',
}

export interface TransitionConfig {
	duration: number | string
	delay: number | string
	easing: string
}

export function transition(
	propertyName: string | string[],
	configOrDuration: TransitionConfig['duration'] | Partial<TransitionConfig>,
): string {
	const propertyNames: string[] =
		propertyName.constructor !== Array ? [propertyName as string] : (propertyName as string[])
	const config: Partial<TransitionConfig> =
		typeof configOrDuration === 'number'
			? { duration: configOrDuration as TransitionConfig['duration'] }
			: (configOrDuration as Partial<TransitionConfig>)

	const { duration, delay, easing = 'ease-in-out' } = config

	return `
		transition-property: ${propertyNames.length ? propertyNames.join(', ') : 'all'};
		${duration ? `transition-duration: ${padUnit(duration, 'ms')};` : ''}
		${delay ? `transition-delay: ${padUnit(delay, 'ms')};` : ''}
		${easing ? `transition-timing-function: ${easing};` : ''}
	`
}
