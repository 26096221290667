import { Workbox } from 'workbox-window'

import history from 'src/lib/history'

export function registerServiceWorker() {
	const wb = new Workbox('/sw.js')

	wb.addEventListener('waiting', () => {
		wb.messageSkipWaiting()
	})

	wb.addEventListener('controlling', () => {
		history.listen(() => {
			window.location.reload()
		})
	})

	wb.register().then((registration) => {
		setInterval(
			() => {
				registration?.update()
			},
			60 * 60 * 1000,
		)
	})
}
