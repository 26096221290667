import { FontWeightAbsolute, Globals } from 'csstype'
import { css } from 'styled-components'

import { padPx } from 'src/css-helpers/units'

export const comfortaa = 'Comfortaa, sans-serif'
export const openSans = "'Open Sans', Roboto, Helvetica, sans-serif"
export const defaultFontFamily = openSans
export const BASE_FONT_SIZE = 16

/**
 * @property {string|FontType} family -  Use `FontType` enum (recommended) or supply your own font face name
 * @property {number} size - Font size will be transformed to `rem` unit based base font size. Available values are: 32, 20, 18, 16, 14, 12, 10, 8
 * @property {string|number} weight - Font weight. Any CSS valid value is valid
 */
export interface FontProps {
	/** Use `FontType` enum (recommended) or supply your own font face name */
	family: FontFamily
	/** Font size will be transformed to `rem` unit based base font size. Also automatically sets line-height appropriately. */
	size: FontSize
	/** Font weight */
	weight: FontWeight
	/** Font style */
	style: Globals | 'italic' | 'normal' | 'oblique'
	letterSpacing: Globals | number | 'normal'
}

export type FontSize = 50 | 40 | 32 | 24 | 23 | 20 | 18 | 16 | 14 | 13 | 12 | 11 | 10 | 8
export type FontWeight =
	| 100
	| 200
	| 300
	| 400
	| 500
	| 600
	| 700
	| 800
	| 900
	| Globals
	| FontWeightAbsolute
	| 'bold'
	| 'normal'
	| 'bolder'
	| 'lighter'

/**
 * @property {boolean} useDefaultBase - if false, only specified properties will be passed, instead of all font properties with default values
 * @property {boolean} useImportant - if true, appends `!important` to each property declaration
 */
export interface FontHelperSettings {
	/** Should the helper supply all font properties, or only the ones used in the override. defaults to `true` */
	useDefaultBase: boolean
	/** Should the helper add !important tags to properties. USE WITH CAUTION! */
	useImportant: boolean
}

export enum FontType {
	OpenSans = 'Open Sans',
	Comfortaa = 'Comfortaa',
	RobotoMono = 'Roboto Mono',
}

export type FontFamily = FontType | string

function isFontProps(obj: any): obj is FontProps {
	return typeof obj === 'object' && Object.keys(obj).some((k) => ['family', 'size', 'weight'].includes(k))
}

function isHelperConf(obj: any): obj is FontHelperSettings {
	return typeof obj === 'object' && Object.keys(obj).some((k) => ['useDefaultBase', 'useImportant'].includes(k))
}

type PropTransformer<T> = (v: T) => [string, any]
type LineHeightCalcFn = (size: FontProps['size'], family: FontProps['family']) => number

const lineHeightMap: Partial<Record<FontProps['size'], LineHeightCalcFn | number>> = {
	50: 60,
	40: 50,
	32: 38,
	24: 38,
	20: 28,
	18: 25,
	16: 22,
	14: 20,
	12: 18,
	10: 14,
	8: 12,
}

export function getLineHeight(fontSize: FontSize, fontFamily?: FontProps['family']): number | undefined {
	const _size = lineHeightMap[fontSize]
	if (typeof _size === 'function') {
		return _size(fontSize, fontFamilyToFont(fontFamily))
	} else {
		return _size
	}
}

function fontFamilyToFont(family?: FontType | string): string {
	return family === FontType.OpenSans
		? defaultFontFamily
		: family === FontType.Comfortaa
			? comfortaa
			: family ?? defaultFontFamily
}

/** Returns CSS text for font properties. */
export function font(
	fontFamily?: FontFamily,
	fontProps?: Partial<Omit<FontProps, 'family'>>,
	helperConf?: Partial<FontHelperSettings>,
): string
export function font(fontProps: Partial<FontProps>, helperConf?: Partial<FontHelperSettings>): string
export function font(
	a: FontFamily | Partial<FontProps> = {},
	b: Partial<Omit<FontProps, 'family'>> | Partial<FontHelperSettings> = {},
	c: Partial<FontHelperSettings> = {},
): string {
	let fontFamily: FontFamily
	let customProps: Partial<Omit<FontProps, 'family'>>
	let customConfig: Partial<FontHelperSettings>

	if (isFontProps(a)) {
		fontFamily = a.family
		const { family, ...rest } = a
		customProps = rest
		if (isHelperConf(b)) {
			customConfig = b
		}
	} else {
		fontFamily = a as FontFamily
		customProps = b as FontProps
		customConfig = c
	}

	const defaultFontProps: FontProps = {
		family: fontFamilyToFont(fontFamily),
		size: 14,
		weight: 'normal',
		style: 'normal',
		letterSpacing: 'normal',
	}
	const defaultConf: FontHelperSettings = {
		useDefaultBase: true,
		useImportant: false,
	}
	const allProps: FontProps = { ...defaultFontProps, ...customProps }
	const config: FontHelperSettings = { ...defaultConf, ...customConfig! }
	const { useDefaultBase } = config
	const important = config.useImportant ? ' !important' : ''
	const propNames: Array<keyof FontProps> = Object.keys(defaultFontProps) as Array<keyof FontProps>
	const propTransformers: object & Partial<Record<keyof FontProps, PropTransformer<FontProps[keyof FontProps]>>> = {
		size: (v) => ['font-size', typeof v === 'number' ? pixelFontSize(v) : v],
		letterSpacing: (v) => ['letter-spacing', padPx(v)],
	}

	return propNames.reduce((str, p) => {
		const transformer = propTransformers.hasOwnProperty(p)
			? propTransformers[p]!
			: (((v: any) => [`font-${p}`, v]) as PropTransformer<any>)
		const [name, value] = transformer(allProps[p])
		if (!useDefaultBase && !customProps[p] && (p !== 'family' || allProps.family === defaultFontFamily)) {
			return str
		}
		let out = str + `\n${name}: ${value}${important};`
		if (p === 'size') {
			const lineHeightValue = getLineHeight(allProps.size, allProps.family)
			out += lineHeightValue ? `\nline-height: ${padPx(lineHeightValue)}${important};` : ''
		}
		return out
	}, '')
}

export function pixelFontSize(pixels: number): string {
	return (pixels / BASE_FONT_SIZE).toFixed(3).replace(/\.?0+$/, '') + 'rem'
}

export const textOverflow = (maxWidth?: number | string) => css`
	${maxWidth ? `max-width: ${padPx(maxWidth)};` : ''}
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`

/** Generate ellipsis for element after text overflows, that spans multiple lines.
 *
 * **Important: Applies font props internally** */
export function textMultilineOverflow(fontProps: Partial<FontProps>, maxLinesToShow: number) {
	const lineHeight = lineHeightMap[fontProps.size!] as number
	const height = lineHeight * maxLinesToShow

	return `
		display: block; /* Fallback for non-webkit */
		display: -webkit-box;
		max-height: ${height}px; /* Fallback for non-webkit */
		${font(fontProps)}
		-webkit-line-clamp: ${maxLinesToShow};
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	`
}
