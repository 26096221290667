import { GetRailsLocationsResponseZ } from 'src/types/rails'

import { getGGClient } from './GetGoingClient'

export async function getRailsLocations(search: string) {
	return getGGClient()
		.request('GET', `rails/locations_search`, {
			responseSchema: GetRailsLocationsResponseZ,
			params: { search },
		})
		.then((r) => r.data)
}
