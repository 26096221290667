import { ExpenseReportsTypes } from 'src/redux/actions/actions.types'
import { denormalize, normalize } from 'src/redux/reducers/expense-reports.reducer'
import { Callback } from 'src/travelsuit'
import { ExpenseReport, ExpenseReportApi } from 'src/travelsuit/expenses'

export const Actions = {
	getReport(id: number, callback?: Callback<void, ExpenseReport>) {
		return {
			type: ExpenseReportsTypes.GetReport.REQUEST,
			payload: { id },
			callback: (expenseReport: ExpenseReportApi) => callback?.(normalize(expenseReport)),
		}
	},
	getReports(callback?: Callback<void, ExpenseReport[]>) {
		return {
			type: ExpenseReportsTypes.GetReports.REQUEST,
			callback: (expenseReportsApi: ExpenseReportApi[]) => callback?.(expenseReportsApi.map(normalize)),
		}
	},
	deleteReport(report: ExpenseReport, callback?: Callback) {
		return {
			type: ExpenseReportsTypes.DeleteReport.REQUEST,
			payload: { id: report.id },
			callback,
		}
	},
	updateReport(expenseReport: ExpenseReport, callback?: Callback) {
		const expenseReportApi = denormalize(expenseReport)
		return {
			type: ExpenseReportsTypes.UpdateReport.REQUEST,
			payload: { id: expenseReportApi.id },
			body: expenseReportApi,
			callback,
		}
	},
	createReport(expenseReport: ExpenseReport, callback?: Callback<void, string>) {
		const expenseReportApi = denormalize(expenseReport)
		return {
			type: ExpenseReportsTypes.CreateReport.REQUEST,
			body: expenseReportApi,
			callback,
		}
	},
	submitReport(expenseReport: ExpenseReport, callback?: Callback) {
		const expenseReportApi = denormalize(expenseReport)
		return {
			type: ExpenseReportsTypes.SubmitReport.REQUEST,
			payload: { id: expenseReport.id },
			body: expenseReportApi,
			callback,
		}
	},
	approveReport(expenseReport: ExpenseReport, callback?: Callback<void, ExpenseReport>) {
		return {
			type: ExpenseReportsTypes.ApproveReport.REQUEST,
			payload: { id: expenseReport.id },
			callback: (expenseReport: ExpenseReportApi) => callback?.(normalize(expenseReport)),
		}
	},
	rejectReport(expenseReport: ExpenseReport, reason: string, callback?: Callback) {
		return {
			type: ExpenseReportsTypes.RejectReport.REQUEST,
			payload: { id: expenseReport.id },
			body: { reason },
			callback,
		}
	},
}
