import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { CreditCardBrandMap } from 'src/assets/credit-card-brands'
import SvgIcon from 'src/atoms/SvgIcon/SvgIcon'
import Tooltip from 'src/atoms/Tooltip/Tooltip'
import { forceArray } from 'src/lib/array-utils'
import { getCreditCardNumbersWithName } from 'src/lib/credit-card-utils'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { insertSeparator } from 'src/lib/insertSeparator'
import QuestionCircleIcon from 'src/refactor/assets/icons/question-circle.svg'
import { disabledGray } from 'src/refactor/colors'
import styled, { textOverflow } from 'src/styles'
import { CreditCardOwnerType, CreditCardType } from 'src/types/creditCard'

const FaqIcon = styled(SvgIcon).attrs(() => ({ src: QuestionCircleIcon }))`
	align-self: baseline;
	width: 16px;
	height: 16px;
	margin-left: 5px;
`

const StyledTooltip = styled(Tooltip)`
	pointer-events: all;
	vertical-align: middle;
`

interface IDisabledCardFaqIconWithTooltip {
	lines: React.ReactNode[]
}

const DisabledCardFaqIconWithTooltip: React.FC<IDisabledCardFaqIconWithTooltip> = ({ lines }) => (
	<StyledTooltip onClick={(e) => e.stopPropagation()} render={() => insertSeparator(lines, <br />)}>
		<FaqIcon />
	</StyledTooltip>
)

const CardOption = styled.div.attrs(addE2EAttrs)<E2E & { disabled?: boolean }>`
	display: flex;
	overflow: hidden;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	color: ${({ disabled }) => (disabled ? disabledGray : 'inherit')};
`

const CardText = styled.span`
	${textOverflow()}
`

const CardBrandImg = styled.img`
	margin-right: 5px;
	width: 30px;
	height: 18px;
`

interface ICreditCardOption {
	cardBrand: CreditCardType
	cardNumbers: string
	cardName: string
	cardOwnerType?: CreditCardOwnerType
	tooltipContent?: React.ReactNode | React.ReactNode[]
	disabled?: boolean
}

export const CreditCardOption: React.FC<ICreditCardOption & E2E> = memo((props) => {
	const { cardBrand, cardNumbers, cardName, cardOwnerType, tooltipContent, e2e, disabled } = props
	const { t } = useTranslation()
	const translation = {
		companyCard: t('trip-payment-method.company-card', 'Company card'),
		travelerCard: t('trip-payment-method.traveler-card', 'Traveler card'),
	}

	const cardOwnerText: Record<CreditCardOwnerType, string> = {
		company: translation.companyCard,
		traveler: translation.travelerCard,
	}

	const tooltipLines = tooltipContent ? forceArray(tooltipContent) : undefined

	return (
		<CardOption e2e={e2e} disabled={disabled}>
			<CardBrandImg src={CreditCardBrandMap[cardBrand]} alt={cardBrand} />
			<CardText>
				{getCreditCardNumbersWithName({ cardName, cardNumbers })} {cardOwnerType ? cardOwnerText[cardOwnerType] : ''}
				{!!tooltipLines?.length && <DisabledCardFaqIconWithTooltip lines={tooltipLines} />}
			</CardText>
		</CardOption>
	)
})
