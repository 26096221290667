import { useCallback, useEffect } from 'react'

import { useMutableValue } from 'src/lib/react-hooks/generic-hooks'

const listeners = new Set<() => void>()

function useBrowserStorage(storage: Storage, key: string) {
	const [_, refresh] = useMutableValue(0)

	useEffect(() => {
		const listener = (event: StorageEvent) => {
			if (event.storageArea === storage && event.key === key) {
				refresh()
			}
		}
		window.addEventListener('storage', listener, false)
		listeners.add(refresh)
		return () => {
			window.removeEventListener('storage', listener, false)
			listeners.delete(refresh)
		}
	}, [key, refresh, storage])

	const setValue = useCallback(
		(value: string | null) => {
			if (value === null) {
				storage.removeItem(key)
			} else {
				storage.setItem(key, value)
			}
			;[...listeners].forEach((l) => l())
		},
		[key, storage],
	)

	return [storage.getItem(key), setValue] as const
}

export function useSessionStorage(key: string) {
	return useBrowserStorage(sessionStorage, key)
}
