import { addUTCOffset, removeUTCOffest } from 'src/lib/dates'
import { getSegmentMeal, TimeObject } from 'src/lib/utils'
import CarryOnIcon from 'src/refactor/assets/flight/carryon.svg'
import LuggageIcon from 'src/refactor/assets/flight/luggage.svg'
import MealIcon from 'src/refactor/assets/flight/meal.svg'
import {
	BaggageAllowance,
	Flight,
	FlightResult,
	FlightResultMinimal,
	FlightSearchRouteDetails,
	MinimalSegment,
	Segment,
} from 'src/travelsuit'

export function FlightMinimalToFlight(minimal: FlightResultMinimal<MinimalSegment>): Flight {
	return {
		...minimal,
		locations: [],
		depart_at: minimal.segments[0].departure_time,
		flight_airline: minimal.segments[0].marketing_airline,
		flight_class: minimal.segments[0].cabin_class,
		flight_id: '',
		flight_number: '',
		land_at: minimal.segments[minimal.segments.length - 1].departure_time,
		price: 0,
		segments: minimal.segments.map(
			(s) =>
				({
					...s,
					arrival_airport_id: s.arrival_airport.id,
					departure_airport_id: s.departure_airport.id,
				}) as any,
		),
		total_duration: 0,
	} as any as Flight //TODO: resolve this mapping
}

export function prepareFlightJson({ ...flight }: FlightResult, searchId: number) {
	flight.flights.forEach((flight) => {
		flight.segments = flight.segments.map(({ ...segment }) => {
			// @ts-expect-error typings are wrong here, need to rewrite overall seat map managing
			segment.seat_selection = (segment.seat_selection || []).map(
				// @ts-expect-error typings are wrong here, need to rewrite overall seat map managing
				({ converted_amount, ...seatSelection }) => seatSelection as (typeof segment.seat_selection)[number],
			)
			return segment
		})
	})
	return { ...flight, search_id: searchId }
}

enum FlightFeatures {
	Baggage = 'baggage',
	Meal = 'meal',
	CarryOn = 'carry-on',
}

interface FeatureIconDetails {
	icon: string
	tooltip: string
	feature: FlightFeatures
	variant: 'included' | 'not_included' | 'extra_charge'
	data?: any
}

const FeatureToIconFunction: Record<string, (flight: Flight) => FeatureIconDetails> = {
	[FlightFeatures.Meal]: (flight: Flight) => {
		if (flight.segments.some((s) => getSegmentMeal(s))) {
			return {
				icon: MealIcon,
				tooltip: 'flights.feature-icons.tooltips.with-meal',
				feature: FlightFeatures.Meal,
				variant: 'included',
			}
		}
		return {
			icon: MealIcon,
			tooltip: 'flights.feature-icons.tooltips.without-meal',
			feature: FlightFeatures.Meal,
			variant: 'not_included',
		}
	},
	[FlightFeatures.Baggage]: (flight: Flight) => {
		if (flight.segments.every((s) => Boolean(s.baggage_allowance?.max_pieces || s.baggage_allowance?.max_weight))) {
			return {
				icon: LuggageIcon,
				tooltip: 'flights.feature-icons.tooltips.with-checked-baggage',
				feature: FlightFeatures.Baggage,
				variant: 'included',
			}
		} else {
			return {
				icon: LuggageIcon,
				tooltip: 'flights.feature-icons.tooltips.without-checked-baggage',
				feature: FlightFeatures.Baggage,
				variant: 'not_included',
			}
		}
	},
	[FlightFeatures.CarryOn]: (flight: Flight) => {
		if (isFlightContainCarryOn(flight)) {
			const allowance = carryOnForSegment(flight.segments[0])
			return {
				icon: CarryOnIcon,
				tooltip: 'flights.feature-icons.tooltips.with-carry-on',
				feature: FlightFeatures.CarryOn,
				variant: 'included',
				data: { description: allowance?.max_weight },
			}
		}
		return {
			icon: CarryOnIcon,
			tooltip: 'flights.feature-icons.tooltips.without-carry-on',
			feature: FlightFeatures.CarryOn,
			variant: 'not_included',
		}
	},
}

function isFlightContainCarryOn(_flight: Flight): boolean {
	return true
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function carryOnForSegment(seg: Segment): BaggageAllowance | undefined {
	return {
		max_pieces: 0,
		max_weight: 20,
		weight_unit: 'K',
	}
}

export function badgesIconsFromFlight(flight: Flight): FeatureIconDetails[] {
	const badgesIcons = [] as any[]

	Object.keys(FeatureToIconFunction).forEach((feature) => {
		badgesIcons.push(FeatureToIconFunction[feature](flight))
	})

	return badgesIcons
}

export function formatFlightDate(date: Date) {
	const dateParts = new Map(
		new Intl.DateTimeFormat('en-GB', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		})
			.formatToParts(removeUTCOffest(date))
			.filter((v) => [`year`, `month`, `day`].includes(v.type))
			.map((entry) => [entry.type, entry.value]),
	)

	return `${dateParts.get('year')}-${dateParts.get('month')}-${dateParts.get('day')}`
}

export function parseRouteDateTime(route: Pick<FlightSearchRouteDetails, 'departure_date' | 'time_restriction'>) {
	const departureDate = addUTCOffset(new Date(route.departure_date!))
	const departureTime = route.time_restriction ? TimeObject.fromString(route.time_restriction.time) : undefined

	departureDate.setHours(departureTime?.hours ?? 0)
	departureDate.setSeconds(0)
	departureDate.setMilliseconds(0)

	return departureDate
}

export function doRoutesHaveSameDatetime(left: FlightSearchRouteDetails, right: FlightSearchRouteDetails) {
	return parseRouteDateTime(left).getTime() === parseRouteDateTime(right).getTime()
}
