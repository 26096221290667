import { z } from 'zod'

export const RailsLocationZ = z.object({
	type: z.enum(['rail_station', 'rail_station_group']),
	id: z.string(),
	country_name: z.string().nullable(),
	country_code: z.string().nullable(),
	name: z.string(),
})

export type RailsLocation = z.infer<typeof RailsLocationZ>

export const GetRailsLocationsResponseZ = z.array(RailsLocationZ)
