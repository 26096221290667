import axios from 'axios'

import { AbortRequestConfigGetter } from './types'

export interface AbortRequestSignal {
	getAbortRequestConfig: AbortRequestConfigGetter
	cancel: () => void
}

export function getAbortRequestSignal(): AbortRequestSignal {
	const source = axios.CancelToken.source()

	return {
		getAbortRequestConfig: () => ({ cancelToken: source.token }),
		cancel: () => source.cancel('Request has been aborted'),
	}
}
