import { black } from 'src/_vars'
import { Tooltip } from 'src/atoms/Tooltip'
import { font } from 'src/refactor/fonts'
import styled, { FontType } from 'src/styles'

export const MobileDialogTitle = styled(Tooltip)`
	display: flex;
	justify-content: space-between;

	color: ${black};
	${font(FontType.Comfortaa, { size: 16, weight: 700 })};

	padding-bottom: ${(p) => p.theme.spacing(3)}px;
`
