import { User } from 'src/travelsuit'
import {
	SetIsMockEnabledRequestZ,
	SetIsTokenizerMockEnabledRequestZ,
	SetIsWorldlineMockEnabledRequestZ,
	UserId,
	UserZ,
} from 'src/types/user'

import { getGGClient } from './GetGoingClient'

export async function setIsMockEnabled(userId: UserId, value: boolean) {
	return getGGClient()
		.request('PUT', `users/${userId}/mock`, {
			requestSchema: SetIsMockEnabledRequestZ,
			responseSchema: UserZ,
			data: {
				mock_enabled: value,
			},
		})
		.then((r) => r.data as User)
}

export async function setIsTokenizerMockEnabled(userId: UserId, value: boolean) {
	return getGGClient()
		.request('PUT', `users/${userId}/tokenizer_mock`, {
			requestSchema: SetIsTokenizerMockEnabledRequestZ,
			responseSchema: UserZ,
			data: {
				tokenizer_mock_enabled: value,
			},
		})
		.then((r) => r.data as User)
}

export async function setIsWorldlineMockEnabled(userId: UserId, value: boolean) {
	return getGGClient()
		.request('PUT', `users/${userId}/worldline_mock`, {
			requestSchema: SetIsWorldlineMockEnabledRequestZ,
			responseSchema: UserZ,
			data: {
				worldline_mock_enabled: value,
			},
		})
		.then((r) => r.data as User)
}
