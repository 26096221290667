import { spacing } from 'src/_vars'
import { RelativeContainer } from 'src/atoms/GenericComponents/GenericComponents'
import { RoundCloseIcon } from 'src/atoms/SvgIcon/SvgIcon'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { white } from 'src/refactor/colors'
import styled, { mediaQuery, size } from 'src/styles'
import { DefaultElProps, HEADER_HEIGHT } from 'src/travelsuit'

export const FloatingFormControlContainer = styled(RelativeContainer).attrs(addE2EAttrs)<
	E2E & { mobileFocused?: boolean } & DefaultElProps<'div'>
>`
	${(props) =>
		mediaQuery.mobileOnly`
		${
			props.mobileFocused
				? `
			position: fixed !important;
			top: ${0}px;
			left: 0;
			bottom: 0;
			right: 0;
			padding: ${spacing}px;
			height: calc(100vh - ${HEADER_HEIGHT}px);
			height: 100vh;
			width: 100vw;
			background: ${white};
			z-index: 100;
		`
				: ''
		}
	`.join('')}

	${(p) => p.theme.breakpoints.down('md')} {
		grid-column: 1 / -1;
	}
`

export const FloatingCloseButtonContainer = styled.div.attrs(addE2EAttrs)<E2E & { visible: boolean }>`
	${size(30)}
	position: absolute;
	top: 5px;
	left: 5px;
	border-radius: 50%;
	position: relative;
	vertical-align: text-top;
	cursor: pointer;
	transition: all 150ms ease-in-out;
	position: absolute;
	margin-top: -2px;
	z-index: 10;

	${mediaQuery.hideAbove('mobile')}
	${(props) => mediaQuery.mobileOnly`display: ${props.visible ? 'block' : 'none'};`.join('')}
`

export const FloatingCloseButton = styled(RoundCloseIcon)`
	position: absolute;
	top: calc(50% + 1px);
	left: calc(50% + 0.5px);
	transform: translate(-50%, -50%);
`
